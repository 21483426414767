import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, DialogActions, DialogContent, Typography, DialogTitle, Dialog } from '@material-ui/core';
import { UserProfileStoreContextItem } from './UserProfileStore';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import urlJoin from 'url-join';
import { FcApproval } from 'react-icons/fc'

export const UnregisterAppDialog = observer(() => {
    const store = UserProfileStoreContextItem.useStore();
    const isOpen = store.unregisterDialogOpen;

    const handleClose = () => {
        store.unregisterDialogOpen = false;
    };

    const handleCancel = () => {
        handleClose();
    }

    const handleUnregister = async () => {
        try {
            await store.unregisterApplication();
        } catch (e) {

        }

        handleClose();
    }

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Unregister from the {store.unregisterApp?.name}?</DialogTitle>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleUnregister} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
})

export const UnverifiedApplicationDialog = observer(() => {
    const userProfileStore = UserProfileStoreContextItem.useStore();
    const history = useHistory();
    const location = useLocation();
    const [didVerification, didVerificationSet] = useState(false);

    
    const parsedQuery = useMemo(() => queryString.parse(location.search), [location.search]);
    const appId = parsedQuery.unVerifiedAppId as string;

    let isOpen = (location.search.indexOf("unVerifiedAppId") > -1) && !userProfileStore.isApplicationVerified(appId)

    const handleClose = () => {
        history.push(history.location.pathname);
    };

    const handleVerify = async () => {
        if (!didVerification) {
            try {
                await userProfileStore.verifyApplication(appId);
                await userProfileStore.reloadUserProfile();
                didVerificationSet(true);
            } catch (e) {
                
            }
        } else {
            if (!application) {
                return;
            }

            const serviceInfo = application?.serviceInfo;
            var p1 = serviceInfo?.appRootUrl ?? '';
            var p2 = serviceInfo?.appEntryPointUrl ?? '';
            var final = urlJoin(p1, p2);
            if (p1 !== '') window.location.href = final;
        }
    }

    const application = userProfileStore.unverifiedApplication;

    useEffect(() => {
        userProfileStore.unverifiedApplicationIdSet(appId);
    }, [appId, userProfileStore]);


    const title = !didVerification ? `Accept Application Verification?` : "Verification Successful!";

    const okMessage = !didVerification ? "Ok" : "Return to application";

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                   
                    <FcApproval style={{ width: "100px", height: "100px", alignSelf: "center" }} />
                    <Typography style={{alignSelf: "center", fontWeight: 300}}>
                        {application?.serviceInfo.appName ?? ""}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleVerify} color="primary" disabled={!application}>
                    {okMessage}
                </Button>
            </DialogActions>
        </Dialog>
    )
})