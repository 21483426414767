import React, { useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import { observer } from 'mobx-react-lite';
import { ApplicationManagementStoreContextItem } from './ApplicationManagementStore';
import {
    Button,
    Checkbox,
    DialogActions,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    DialogTitle,
    Dialog,
} from '@material-ui/core';

export interface InviteUserDialogProps {
    open: boolean;
    onClose: () => void;
}

export const InviteUserDialog = observer((props: InviteUserDialogProps) => {
    const { onClose, open } = props;
    const store = ApplicationManagementStoreContextItem.useStore();
    const [email, emailSet] = useState("")
    const [roles, rolesSet] = useState<string[]>([])
    const handleClose = () => {
        onClose();
    };

    const onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        emailSet(event.target.value);
    }

    const onToggleRole = (role: string) => () => {
        const index = roles.findIndex((item) => {
            return item === role;
        });

        if (index !== -1) {
            const newRoles = roles.filter((item) => item !== role);
            rolesSet(newRoles);
        } else {
            rolesSet(() => [role, ...roles])
        }
    }

    const onInviteUserClick = async () => {
        try {
            await store.registerUser(email, roles);
            onClose();
        } catch (e) {

        }
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Invite Someone to {store.application?.name}</DialogTitle>
            <List>
                {store.application?.rolesAvailable.map((role) => (
                    <ListItem button key={role.name} onClick={onToggleRole(role.name)} >
                        <ListItemAvatar>
                            <Checkbox checked={roles.findIndex((item) => item === role.name) !== -1} onChange={onToggleRole(role.name)} />
                        </ListItemAvatar>

                        <Typography component="span">
                            {role.name}
                            <Typography style={{ fontWeight: 300 }}>
                                {role.description}
                            </Typography>
                        </Typography>


                    </ListItem>
                ))}
                <ListItem autoFocus>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr auto", flex: 1 }}>
                        <TextField helperText="Email Address" onChange={onEmailChanged} style={{ margin: "4px 10px 4px 10px" }} />
                        <Button startIcon={<PersonIcon />} variant="text" onClick={onInviteUserClick} style={{ marginLeft: "10px" }}>
                            Invite User
                        </Button>
                    </div>
                </ListItem>
            </List>
        </Dialog>
    );
});

export const UnregisterUserDialog = observer(() => {
    const store = ApplicationManagementStoreContextItem.useStore();
    const authIdentity = store.unregisterDialogIdentity;
    const isOpen = store.unregisterDialogOpen;

    const handleClose = () => {
        store.unregisterDialogOpen = false;
    };

    const handleCancel = () => {
        handleClose();
    }

    const handleUnregister = async () => {

        if (!authIdentity) {
            return;
        }
        try {
            await store.unregisterUser(authIdentity.email);
        } catch (e) {

        }

        handleClose();
    }

    if (!authIdentity) {
        return null;
    }

    return (
        <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle>Unregister {authIdentity.email}?</DialogTitle>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleUnregister} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
})
