import React, { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Avatar, Link, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { AuthStoreContextItem, securityAdminRole } from "../../Stores/AuthStore";
import { observer } from "mobx-react-lite";
import gravatar from 'gravatar'
import { useHistory } from "react-router-dom";
import { ItemRepositoryContextItem } from "../../Stores/ItemRepository";
import { computed } from "mobx";
import AuthIcon from '@material-ui/icons/Security'

const AvatarStyled = styled(Avatar)`
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
`
interface ComputedValueObject<T> {
    value: T;
}

function useComputed<T>(
    func: () => T
) {
    return React.useMemo<ComputedValueObject<T>>(() => {
        const computedValue = computed(func);

        return {
            get value() {
                return computedValue.get();
            }
        };
    }, [func]);
}

const UserAvatar: FunctionComponent = observer(() => {
    const authStore = AuthStoreContextItem.useStore();
    const itemRepo = ItemRepositoryContextItem.useStore();

    const configurableApps = useComputed(() => {
        if (itemRepo.applications.apps.length === 0) {
            return [];
        }

        const result = itemRepo.applications.apps.filter((app) => {
            const found = app.rolesAvailable.find((role) => {
                return role.name.toLowerCase() === securityAdminRole;
            })

            return Boolean(found);
        });

        return result ?? [];
    })

    const hasConfigurableApps = configurableApps.value.length > 0 || authStore.isSuperUser;

    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState<any>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback((action: "logout" | "profile" | "accessManagement" | "" = "") => () => {
        if (action === "logout") {
            window.location.href = "./auth/v1/user/logout";
        } else if (action === "profile") {
            history.push("/setup/profile");
        } else if (action === "accessManagement") {
            history.push("/setup/accessManagement");
        }
        setAnchorEl(null);
    }, [history, setAnchorEl]);

    const gravatarUrl = useMemo(() => {
        const gravatarOptions: gravatar.Options = {
            d: "robohash",
            s: "120",
            protocol: 'https'
        }
        return gravatar.url(authStore?.userProfile?.email ?? "", gravatarOptions)
    }, [authStore?.userProfile?.email]);

    if (!authStore || !authStore.userProfile) {
        return null;
    }

    return (
        <Tooltip title={Boolean(anchorEl) ? "" : authStore.userProfile.email} placement="top">
            <div style={{ display: "flex", }}>
                <AvatarStyled src={gravatarUrl} onClick={handleClick} />
                <Menu
                    id="user-avatar"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    disableScrollLock={true}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose()}
                >
                    <MenuItem
                        onClick={handleClose("profile")}>
                        Profile
                    </MenuItem>
                    {
                        (hasConfigurableApps || itemRepo.applications.loading) && (
                            <MenuItem
                                disabled={itemRepo.applications.loading}
                                onClick={handleClose("accessManagement")}>
                                Access Management
                            </MenuItem>
                        )
                    }
                    <MenuItem
                        onClick={handleClose("logout")}>
                        Logout
                    </MenuItem>
                </Menu>
            </div>
        </Tooltip>
    )
})

export const UserHeaderOptions: FunctionComponent = observer(() => {
    const authStore = AuthStoreContextItem.useStore();

    useEffect(() => {
        authStore.getProfile();
    }, [authStore]);

    const loading = authStore.loading;
    const loggedIn = authStore.isLoggedIn;

    const message = loading ? "loading..." : loggedIn ? `${authStore.userProfile?.username} logout` : "login";

    if (loading && !authStore.userProfile) {
        return (
            <Typography
                variant="subtitle2"
                style={{ color: "white" }}>
                {message}
            </Typography>
        )
    }

    if (!loggedIn) {
        return (
            <Link
                href="auth/v1/user/login"
                variant="subtitle2"
                style={{ color: "white", display: "flex" }}>
                {message}&nbsp;<AuthIcon fontSize="small" />
            </Link>
        )
    }

    return <UserAvatar />
});