import React, { CSSProperties, FunctionComponent, useCallback } from "react";
import styled from "styled-components";
import { Avatar, Paper, PaperProps, Typography, TypographyProps, Snackbar, IconButton, Tooltip, TableCell, Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { UserProfileStoreContextItem } from "../UserProfileStore";
import { Edit as EditIcon, Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons'
import { FcOk, FcHighPriority } from "react-icons/fc";
import { useHistory } from "react-router";

export const EDIT_MODE_QUERY_PARAM = "edit"

export const RoleTableCell = styled(TableCell)`
    max-width: 100%;
    overflow: hidden;
    padding: 2px;
`

export const AvatarStyled = styled(Avatar)`
    width: 150px;
    height: 150px;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(127, 127, 127, 1);
`

export const ProfileArea = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
`

export const AvatarArea = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    @media (max-width: 768px) {
        display: none;
      }
`

export const ProfileContent = styled.div`
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 10px;
    border-left-style: solid;
    border-left-width: 1px;
    @media (max-width: 768px) {
        border-left-width: 0px;
      }
`

export const ProfileTopContainer = styled.div`
    border-bottom-style: solid;
    border-bottom-width: 1px;
`

export const ProfileBottomContainer = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
`

export const ProfileInfoHorizontalArea = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 10px;
`

export const ProfileInfoVerticalArea = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`

export const ProfileInfoTitleStyled = styled(Typography)`
    font-weight: bold;
`

export const PaperComponent = (props: PaperProps) => {
    return (
        <Paper {...props} elevation={3} />
    )
}

export const ProfilePaper = styled(PaperComponent)`
    margin: 10px;
    padding: 10px;
    overflow: hidden;
`

export const ApplicationListPaper = styled(PaperComponent)`
    margin: 0px 10px 10px 10px;
    padding: 10px;
    overflow: hidden;
`

export const ProfileRoot = styled.div`
    display: flex;
    flex-direction: column;
`

export const DenseTableCell = styled(TableCell)`
    padding: 2px;
`


export const ProfileInfoItem = (props: TypographyProps) => {
    return (
        <Typography variant="body2" {...props} />
    )
}

export const ProfileInfoTitle = (props: TypographyProps) => {
    return (
        <ProfileInfoTitleStyled variant="body2" {...props} />
    )
}


export const ProfileInfoHorizontal: FunctionComponent<{ label: string, data: string }> = observer((props) => {
    const { label, data } = props;
    return (
        <ProfileInfoHorizontalArea>
            <ProfileInfoTitle>
                {label} &nbsp;
            </ProfileInfoTitle>
            <ProfileInfoItem>
                {data}
            </ProfileInfoItem>
        </ProfileInfoHorizontalArea>
    )
})

export const ProfileInfoVertical: FunctionComponent<{ label: string, data: string }> = observer((props) => {
    const { label, data } = props;

    return (
        <ProfileInfoVerticalArea>
            <ProfileInfoTitle>
                {label} &nbsp;
        </ProfileInfoTitle>
            <ProfileInfoItem>
                {data ?? "-"}
            </ProfileInfoItem>
        </ProfileInfoVerticalArea>
    )
});


export const NoApplications = observer(() => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h5" style={{ fontWeight: 300, textAlign: "center" }}>
                No Applications Registered
            </Typography>
        </div>
    )
})


export const UserProfileAvatar = observer(() => {
    const userProfileStore = UserProfileStoreContextItem.useStore();
    const profile = userProfileStore.profile;

    if (!profile) {
        return null;
    }

    return (
        <AvatarArea>
            <AvatarStyled src={userProfileStore.gravatarUrl} style={{ alignSelf: "center" }} />
            <Typography style={{ alignSelf: "center" }}>
                {(profile.firstName ?? "-") + " " + (profile.lastName ?? "")}
            </Typography>
        </AvatarArea>
    )
})

export const UserProfileSnackbar = observer(() => {
    const userProfileStore = UserProfileStoreContextItem.useStore();
    const handleSnackbarClose = useCallback(() => {
        userProfileStore.errorMessageSet();
    }, [userProfileStore])

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={Boolean(userProfileStore.errorMessage)}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={userProfileStore.errorMessage}
            action={
                <React.Fragment>
                    <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    )
})


export const VerifiedIcon: FunctionComponent<{ appId: string, style?: CSSProperties }> = observer((props) => {
    const { appId, style } = props;
    const userProfileStore = UserProfileStoreContextItem.useStore();
    const styleMods = { width: "20px", height: "20px" };
    const newStyle = { ...style, ...styleMods };

    const onResendInviteClick = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        try {
            await userProfileStore.verifyApplication(appId);
            await userProfileStore.reloadUserProfile();
        } catch (e) {

        }
    }, [userProfileStore, appId])

    const verified = userProfileStore.profile?.appAccess.find((item) => item.appId === appId)?.verified ?? false

    const tooltipTitle = verified ? "" : "Accept invitation";

    return (
        <div style={{ display: "flex" }}>
            <Tooltip title={tooltipTitle}>
                <IconButton onClick={onResendInviteClick} disabled={verified}>
                    {verified ? <FcOk style={newStyle} /> : <FcHighPriority style={newStyle} />}
                </IconButton>
            </Tooltip>
        </div>
    )
})

export const UserProfileEditButton: FunctionComponent<{ isEditMode: boolean }> = observer((props) => {
    const { isEditMode } = props;
    const history = useHistory();
    const onProfileEdit = useCallback(() => {
        if (isEditMode) {
            const newLocation = history.location.pathname;
            history.push(newLocation)
        } else {
            const newLocation = `${history.location.pathname}?${EDIT_MODE_QUERY_PARAM}`
            history.push(newLocation)
        }

    }, [history, isEditMode]);

    return (
        <Button startIcon={!isEditMode ? <EditIcon /> : <DoneIcon />} onClick={onProfileEdit}>
            {!isEditMode ? "Edit" : "Done"}
        </Button>
    )
})
