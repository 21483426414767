import React from "react";
import { Link, Typography } from "@material-ui/core";
import styled from "styled-components";
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { UserHeaderOptions } from "./UserHeaderOptions";
import Logo from '../Logo'
import AboutIcon from '@material-ui/icons/Info'
import SupportIcon from '@material-ui/icons/ContactSupport';

const MainHeaderRoot = styled(motion.div)`
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 15;
    background-color: rgba(0, 183, 255, 0);
    color: rgba(21, 21, 21, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0 24px;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 0.0200rem;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 1);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(20px)
`

const LogoContainer = styled.div`
    width: 120px;
    height: 120px;
    filter: drop-shadow(0px 1px 1px rgba(0,0,0,0.8));
    pointer-events: none;
    @media (max-width: 768px) {
        display: none;
      }
`

const TitleContainer = styled.div`
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 10;
`

const VersionContainer = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10;
`

const List = styled.ul`
    z-index: 10;
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 0px;
`

const ListItem = styled.li`
    margin: 0 5px;
`

const HeaderImage = styled(motion.img)`
    position: absolute;
    width: 100%;
    height: 340px;
    object-fit: cover;
    object-position: center;
`

const AboutListItem = styled(ListItem)`
    @media (max-width: 450px) {
        display: none;
    }
`

const MainHeader = () => {
    const { scrollY } = useViewportScroll();
    const height = useTransform(scrollY, [0, 100], [240, 120]);
    const opacity = useTransform(scrollY, [50, 100], [1, 0]);

    const filter = useTransform(
        scrollY,
        [0, 100],
        ["saturate(1)", "saturate(0.3)"]
    );

    return (

        <MainHeaderRoot
            id="navigation"
            style={{
                height
            }}
        >

            <VersionContainer>

                <Typography style={{ color: "#f3f3f3", marginRight: 10, fontSize: 10 }}>Portal v{process.env.REACT_APP_VERSION}</Typography>

            </VersionContainer>

            <HeaderImage
                style={{ top: "0px", filter: filter }}
                src="resources/hero8.jpg"
            />
            <HeaderImage
                style={{ top: "0px", opacity: opacity }}
                src="resources/hero7.jpg"
            />
            <TitleContainer>
                <LogoContainer>
                    <Logo style={{ height: "100%" }} />
                </LogoContainer>
                <Typography variant="h2" style={{ color: "#f3f3f3", fontWeight: 100, marginLeft: "10px" }}>e|Connect:</Typography>
                <Typography variant="h2" style={{ color: "#f3f3f3", fontWeight: 400 }}>Cloud</Typography>
            </TitleContainer>
            <List>
                <ListItem>
                    <Link href="https://www.econnectglobal.com/support" style={{ display: "flex" }}>
                        <Typography variant="subtitle2" style={{ color: "white", cursor: "pointer" }}>support&nbsp;</Typography>
                        <SupportIcon fontSize="small" style={{ color: "white", cursor: "pointer" }} />
                    </Link>
                </ListItem>
                <AboutListItem>
                    <Link href="https://www.econnectglobal.com/about-us" style={{ display: "flex" }}>
                        <Typography variant="subtitle2" style={{ color: "white", cursor: "pointer" }}>about&nbsp;</Typography>
                        <AboutIcon fontSize="small" style={{ color: "white", cursor: "pointer" }} />
                    </Link>
                </AboutListItem>
                <ListItem>
                    <UserHeaderOptions />
                </ListItem>
            </List>
        </MainHeaderRoot>

    );
};

export default MainHeader;
