import React, { useCallback, FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { motion } from "framer-motion";
import Color from 'color';
import { useTheme, Theme, Typography } from '@material-ui/core';
import { Card } from './Card'
import { observer } from 'mobx-react-lite';
import { applicationMetadataLookup } from '../Application/ApplicationMetadata';
import { ItemRepositoryContextItem } from '../Stores/ItemRepository';
import urlJoin from 'url-join';
import { defaultAppMetadata } from '../Application/ApplicationMetadata';
import { BusyOverlay } from '../Application/BusyOverlay';

const RootElement = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    flex-wrap: wrap;
    overflow: auto;
    padding: 15px;
    overflow: visible;
`

const ScreenButtonContainer = styled.div<{ theme: Theme }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    height: 100%;
    width: 100%;
    z-index: 10;
    background: ${props => Color(props.theme.palette.background.paper).darken(0.03).toString()};
    border-style: solid;
    border-width: 1px;
    border-color: ${props => Color(props.theme.palette.divider).toString()};
`

const ScreenButtonLabel = styled(Typography)`
    font-weight: 300 !important;
    margin-bottom: 5px !important;
    margin-left: 5px !important;
    background: rgba(40, 40, 50, 0.75);
    border-radius: 1em;
    border-style: solid;
    border-width: 0px;
    border-color: rgba(10, 10, 10, 0.4);
    padding-left: 10px;
    padding-right: 10px;
    cursor: default;
    z-index: 10;
    color: white;
    font-size: 18px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7) inset;
`
const itemVariants = {
    open: {
        x: 0,
        opacity: 1,
        transition: {
            x: { type: "spring", damping: 25, stiffness: 300, velocity: -100 }
        }
    },
    closed: {
        x: -20,
        opacity: 0,
        transition: {
            y: { stiffness: 200 }
        }
    }
};

const MainScreenButton: FunctionComponent<{ children: ReactNode, label: string }> = (props) => {
    const { children, label } = props;
    const theme = useTheme();

    return (

        <ScreenButtonContainer
            theme={theme}
        >
            <div style={{ position: "absolute" }} >
                {children}
            </div>
            <div style={{ display: "flex", flex: "1", alignItems: "flex-end", alignSelf: "flex-start" }}>
                <ScreenButtonLabel
                    style={{ marginBottom: "20px" }}
                    variant="h6"
                >
                    {label}
                </ScreenButtonLabel>
            </div>
        </ScreenButtonContainer>


    )
}

const variants = {
    open: {
        transition: { staggerChildren: 0.03, delayChildren: 0.1 }
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};

const RootMotion = styled(motion.div)`
    z-index: 5;
    align-items: flex-start;
    flex-wrap: wrap;
    display: flex;
    align-self: center;
    flex: 1;
    justify-content: center;
    overflow: hidden;
    padding-bottom: 40px;
    overflow: visible;
`

const Welcome: FunctionComponent = observer(() => {
    const itemRepo = ItemRepositoryContextItem.useStore();
    const iconStyle = { width: "345px", height: "350px" };

    const onNavigate = useCallback((serviceInfo) => () => {

        const p1 = serviceInfo?.appRootUrl ?? '';
        const p2 = serviceInfo?.appEntryPointUrl ?? '';
        const final = urlJoin(p1, p2);;
        if (p1 !== '') window.location.href = final;

    }, []);

    const apps = itemRepo.applications.groupedApps;

    return (
        <RootElement>
            <BusyOverlay isBusy={itemRepo.applications.loading} />
            {
                apps.map((stack, stackIndex) => {
                    const apps = stack.apps.filter(x => x.serviceInfo.appType !== 'alert-bridge');

                    if(apps.length === 0) {
                        return <React.Fragment key={stackIndex}></React.Fragment>;
                    }

                    return (
                        <div style={{ display: "flex", flexDirection: "column" }} key={stackIndex}>
                            <Typography variant="h5" style={{
                                alignSelf: "center",
                                fontWeight: 300,
                            }}
                            >
                                {stack.customerName || " "}
                            </Typography>
                            {
                                <RootMotion initial={"closed"} animate="open" variants={variants} >
                                    {
                                        apps.map((item, index) => {

                                            return (
                                                <motion.div key={item.name} variants={itemVariants}>
                                                    <Card onClick={onNavigate(item.serviceInfo)}>
                                                        <MainScreenButton key={index} label={item.serviceInfo.appName} >
                                                            {
                                                                applicationMetadataLookup[item.serviceInfo.appType] ?
                                                                    applicationMetadataLookup[item.serviceInfo.appType].icon(iconStyle)
                                                                    : defaultAppMetadata.icon(iconStyle)
                                                            }
                                                        </MainScreenButton>
                                                    </Card>
                                                </motion.div>
                                            )
                                        })
                                    }
                                </RootMotion>
                            }
                        </div>
                    )
                })
            }
        </RootElement>
    )
})

export default Welcome;