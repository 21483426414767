import { DisposableBase } from "../../Types";
import { createStoreContextItem } from "../StoreContextItem";
import { AuthStore, AuthStoreContextItem } from "./AuthStore";
import { ApplicationRepository } from "./ApplicationRepository";

export class ItemRepository extends DisposableBase {
    private _applications: ApplicationRepository
    private _authStore: AuthStore;

    constructor(authStore: AuthStore) {
        super();

        this._authStore = authStore;
        this._applications = new ApplicationRepository(this);
    }

    get auth() {
        return this._authStore;
    }

    get applications() {
        return this._applications;
    }
}

export const ItemRepositoryContextItem = createStoreContextItem(() => {
    const authStore = AuthStoreContextItem.useStore();

    return () => new ItemRepository(authStore);
});