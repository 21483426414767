import React, { ChangeEvent, CSSProperties, FunctionComponent, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Divider, TextField } from "@material-ui/core";
import { UserProfileStoreContextItem } from "./UserProfileStore";
import { observer } from "mobx-react-lite";
import SaveIcon from '@material-ui/icons/Save'
import ChangePassIcon from '@material-ui/icons/Security'
import { useHistory } from "react-router";

const FormParent = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: auto auto;
    column-gap: 10px;
    row-gap: 10px;
    border-radius: 5px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.02);
    align-self: flex-start;
`

const FormCell = styled.div`
    align-self: center;
    margin: 5px;
    padding: 5px;
`

const PasswordChangerRoot = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-self: center;
  
    border-radius: 5px;
`

const PasswordChangerFields = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    border-radius: 5px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.02);
`

const PasswordChanger: FunctionComponent<{ style: CSSProperties }> = observer((props) => {
    const { style } = props;
    const [currentPassword, currentPasswordSet] = useState("");
    const [newPassword, newPasswordSet] = useState("");
    const userProfileStore = UserProfileStoreContextItem.useStore();
    const editorStore = userProfileStore.profileEditor;

    const onCurrentPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        currentPasswordSet(event.target.value);
    }, [currentPasswordSet]);

    const onNewPasswordChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        newPasswordSet(event.target.value);
    }, [newPasswordSet]);


    const onChangePassword = useCallback(async () => {
        if (await editorStore.changePassword(currentPassword, newPassword)) {
            newPasswordSet("");
            currentPasswordSet("")
        }

    }, [currentPassword, newPassword, editorStore])

    return (
        <PasswordChangerRoot style={style}>
            <PasswordChangerFields>
                <TextField type="password" value={currentPassword} onChange={onCurrentPasswordChange} helperText="Current Password" style={{ margin: "10px 10px 0px 10px" }} />
                <TextField type="password" value={newPassword} onChange={onNewPasswordChange} helperText="New Password" style={{ margin: "10px 10px 0px 10px" }} />
            </PasswordChangerFields>
            <Button startIcon={<ChangePassIcon />} onClick={onChangePassword} style={{ margin: "10px", alignSelf: "flex-start" }}>
                Change
            </Button>
        </PasswordChangerRoot >
    )
})

export const ProfileEditor: FunctionComponent<{ style?: CSSProperties }> = observer((props) => {
    const { style } = props;
    const userProfileStore = UserProfileStoreContextItem.useStore();
    const editorStore = userProfileStore.profileEditor;
    const history = useHistory();

    const onFieldChanged = useCallback((fieldName: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const profile: any = editorStore.profile;

        profile[fieldName] = event.target.value;
    }, [editorStore]);

    const onSaveProfile = useCallback(async () => {
        const result = await editorStore.save();

        if (result) {
            const newLocation = history.location.pathname;
            history.push(newLocation)
        }
    }, [editorStore, history]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <FormParent style={style}>
                <FormCell>
                    <TextField helperText="First Name"
                        style={{ width: "120px" }}
                        onChange={onFieldChanged("firstName")}
                        value={editorStore.profile?.firstName}
                    />
                </FormCell>

                <FormCell>
                    <TextField helperText="Last Name"
                        style={{ width: "120px" }}
                        onChange={onFieldChanged("lastName")}
                        value={editorStore.profile?.lastName}
                    />
                </FormCell>

                <FormCell>
                    <TextField helperText="Mobile"
                        style={{ width: "120px" }}
                        onChange={onFieldChanged("mobilePhone")}
                        value={editorStore.profile?.mobilePhone}
                    />
                </FormCell>

                <FormCell>
                    <TextField helperText="Email"
                        style={{ width: "220px" }}
                        onChange={onFieldChanged("email")}
                        value={editorStore.profile?.email}
                    />
                </FormCell>

                <FormCell>
                    <TextField helperText="Username"
                        style={{ width: "220px" }}
                        onChange={onFieldChanged("username")}
                        value={editorStore.profile?.username}
                    />
                </FormCell>

            </FormParent>
            <Button onClick={onSaveProfile} startIcon={<SaveIcon />} style={{ alignSelf: "flex-start", margin: "10px", }} >
                Apply
            </Button>
            <Divider style={{ margin: "10px 35px 25px 5px", maxWidth: "50%" }} />
            <PasswordChanger style={{ alignSelf: "flex-start" }} />
        </div>
    )
});