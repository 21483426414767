import { makeObservable, observable, computed, runInAction, action } from 'mobx'
import { computedFn } from 'mobx-utils';
import { createStoreContextItem } from '../../Common/StoreContextItem';
import { DisposableBase } from '../../Types';
import { ItemRepository, ItemRepositoryContextItem } from '../../Common/Stores/ItemRepository';
import { AuthStore, AuthStoreContextItem, restClient, securityAdminRole } from "../../Common/Stores/AuthStore"
import { AuthAppRoleItem, AuthUserIdentityItem, ChangeAccessForUserParameters, RequestParams } from '../../Clients/Api';

export class ApplicationManagementStore extends DisposableBase {
    private _isBusy = false;
    private _abortController: AbortController = new AbortController();
    private _itemRepo: ItemRepository;
    private _userIdentities: AuthUserIdentityItem[] = [];
    private _appId: string | null = null;
    private _error: string | null = null;
    private _authStore: AuthStore;

    constructor(itemRepo: ItemRepository, authStore: AuthStore) {
        super()
        this._authStore = authStore;
        this._itemRepo = itemRepo;

        makeObservable<ApplicationManagementStore, "_isBusy" | "_userIdentities" | "_appId" | "_error">(this, {
            _isBusy: observable,
            _appId: observable,
            _userIdentities: observable,
            _error: observable,
            error: computed,
            isBusy: computed,
            toggleRole: action,
            application: computed,
            userIdentities: computed,
            saveUser: action,
            registerUser: action,
            unregisterUser: action,
            loadApplication: action,
            resetError: action,
            unregisterDialogOpen: observable,
            unregisterDialogIdentity: observable,
            unregisterDialogOpenSet: action,
            unregisterDialogIdentitySet: action,
            canModifyRoles: computed
        })

        this._disposers.push(() => this._abortController.abort())
    }

    private get requestParameters(): RequestParams {
        return { signal: this._abortController.signal };
    }

    unregisterDialogOpen = false;

    unregisterDialogOpenSet(value: boolean) {
        this.unregisterDialogOpen = value;
    }

    unregisterDialogIdentity: AuthUserIdentityItem | null = null;

    unregisterDialogIdentitySet(value: AuthUserIdentityItem | null) {
        this.unregisterDialogIdentity = value;
    }

    get error() {
        return this._error;
    }

    resetError() {
        this._error = null;
    }

    get appId() {
        return this._appId;
    }

    get application() {
        if (!this.appId) {
            return null;
        }

        return this.getApplicationById(this.appId);
    }

    get canModifyRoles() {
        const isSuperUser = this._authStore.isSuperUser;

        if (isSuperUser) {
            return true;
        }

        if (!this._userIdentities || this._userIdentities.length === 0) {
            return false;
        }

        const user = this._userIdentities.find((item) => {
            return item.userId === this._authStore.userProfile?.userId;
        });

        if (!user) {
            return false;
        }

        const appAccess = user.appAccess.find((item) => {
            return item.appId === this._appId;
        })

      
        if (!appAccess) {
            return false;
        }

        const foundRole = appAccess.roleNamesAssigned.find((role) => {
            return securityAdminRole === role.toLowerCase();
        });
       
        if (foundRole) {
            return true;
        }

        return false;
    }

    async loadApplication(appId: string) {
        this._appId = appId;

        this._isBusy = true;

        try {
            const result = await restClient.api.userManagementGetAllFromAppIds({ appId: [appId] }, this.requestParameters)

            runInAction(() => {
                this._userIdentities = result.data ?? [];
            });
        } catch (e: any) {
            runInAction(() => {
                this._error = e.error.message as string;
            })
        } finally {
            runInAction(() => {
                this._isBusy = false;
            })
        }
    }

    get userIdentities() {
        return this._userIdentities;
    }

    private appAccess(identity: AuthUserIdentityItem) {
        if (!this._appId) {
            return null;
        }

        const appAccess = !identity.appAccess ? null : identity.appAccess.find((item) => item.appId);

        return appAccess;
    }

    roleIndex(identity: AuthUserIdentityItem, role: AuthAppRoleItem) {
        const appAccess = this.appAccess(identity);

        if (!appAccess || !appAccess.roleNamesAssigned) {
            return -1;
        }

        const roleIndex = appAccess.roleNamesAssigned.findIndex((roleName) => {
            return roleName === role.name;
        });

        return roleIndex;
    }

    toggleRole(identity: AuthUserIdentityItem, role: AuthAppRoleItem) {
        const roleIndex = this.roleIndex(identity, role);
        const appAccess = this.appAccess(identity);

        if (!appAccess) {
            return;
        }

        if (!appAccess.roleNamesAssigned) {
            appAccess.roleNamesAssigned = [];
        }

        if (roleIndex > -1) {
            appAccess.roleNamesAssigned.splice(roleIndex, 1);
        } else {
            appAccess.roleNamesAssigned.push(role.name);
        }
    }

    async registerUser(email: string, roles: string[]) {
        if (!this._appId) {
            return;
        }
        
        this._isBusy = true;

        const parameters: ChangeAccessForUserParameters = { email, permittedRoles: roles };

        try {
            await restClient.api.userManagementChangeAccessForUser(this._appId, parameters, this.requestParameters);

            await new Promise(resolve => setTimeout(resolve, 1000));

            await this.loadApplication(this._appId);
        } catch (e: any) {
            runInAction(() => {
                this._error = e.error.message as string;
            })

            throw e;
        }
        finally {
            runInAction(() => {
                this._isBusy = false;
            })
        }
    }

    async unregisterUser(email: string) {
        if (!this._appId) {
            return;
        }

        this._isBusy = true;

        const parameters: ChangeAccessForUserParameters = {
            email,
            permittedRoles: undefined
        };

        try {
            await restClient.api.userManagementChangeAccessForUser(this._appId, parameters, this.requestParameters);

            this._userIdentities = this._userIdentities.filter((item) => {
                return item.email !== email;
            })
        } catch (e: any) {
            runInAction(() => {
                this._error = e.error.message as string;
            })

            throw e;
        }
        finally {
            runInAction(() => {
                this._isBusy = false;
            })
        }
    }

    async resendInvite(identity: AuthUserIdentityItem) {
        if (!this._appId) {
            return;
        }

        this._isBusy = true;

        try {
            await restClient.api.userManagementResendAppRegistrationEmail(this._appId, { email: identity.email }, this.requestParameters);
        } catch (e: any) {
            runInAction(() => {
                this._error = e.error.message as string;
            })
        } finally {
            runInAction(() => {
                this._isBusy = false;
            })
        }
    }

    async saveUser(identity: AuthUserIdentityItem) {
        if (!this._appId) {
            return;
        }

        this._isBusy = true;

        const parameters: ChangeAccessForUserParameters = {
            email: identity.email,
            permittedRoles: this.appAccess(identity)?.roleNamesAssigned
        }

        try {
            await restClient.api.userManagementChangeAccessForUser(this._appId, parameters, this.requestParameters);
        } catch (e: any) {
            runInAction(() => {
                this._error = e.error.message as string;
            })
        }
        finally {
            runInAction(() => {
                this._isBusy = false;
            })
        }
    }

    getApplicationById = computedFn((id: string) => {
        return this._itemRepo.applications.getApplicationById(id)
    });

    get isBusy() {
        return this._isBusy || this._itemRepo.applications.loading;
    }
}

export const ApplicationManagementStoreContextItem = createStoreContextItem(() => {
    const authStore = AuthStoreContextItem.useStore();
    const itemRepo = ItemRepositoryContextItem.useStore();
    return () => new ApplicationManagementStore(itemRepo, authStore);
});