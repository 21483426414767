
import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { RoleRoute } from "../../App";
import { UserProfileStoreContextItem } from "./UserProfileStore";
import { UserProfile } from "./UserProfile";

const UserProfileRoot = () => (
    <UserProfileStoreContextItem.ProviderComponent>
        <UserProfile />
    </UserProfileStoreContextItem.ProviderComponent>
)

export const UserProfileIndex = observer(() => {
    const match = useRouteMatch();
    return (
        <Switch>
            <RoleRoute path={`${match.path}/:userId?`} >
                <UserProfileRoot />
            </RoleRoute>
            <Route>
                Access Denied
            </Route>
        </Switch>
    )
})