import React from "react";
import { observer } from 'mobx-react-lite'
import MediaTubesImage from '../../Resources/media-tubes.jpg'

const Logo = observer((props: any) => {
    const style = props.style;
    return (
        <div style={{ background: "black" }}>
            <img alt="" style={{ objectFit: "contain", ...style }} src={MediaTubesImage} />
        </div>
    )
});

export default Logo;
