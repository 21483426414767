import { Table, TableCell, TableHead, Typography, TableRow, TableBody, Button, Paper, Link } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { FunctionComponent } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { AccessManagementStoreContextItem } from "./AccessManagementStore";
import SettingsIcon from '@material-ui/icons/Settings'
import { BreadcrumbNavigation } from "../../Common/Navigation";
import urlJoin from 'url-join';
import { BusyOverlay } from "../../Common/Application/BusyOverlay";
import ListIcon from '@material-ui/icons/List';

const NoApplications = observer(() => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h2">
                No Applications Registered
            </Typography>
        </div>
    )
})

const ApplicationListPaper = styled(Paper)`
    margin: 0px 10px 20px 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`

const LogsButton = styled(Button)`
    margin-left: 10px;
    @media (max-width: 568px) {
        display: none;
    }
`

const LogsTableCell = styled(TableCell)`
    @media (max-width: 568px) {
        display: none;
    }
`

const ApplicationList = observer(() => {
    const store = AccessManagementStoreContextItem.useStore();
    const history = useHistory();

    const manageAppClick = useCallback((appId: string) => () => {
        history.push(`./accessManagement/${appId}`)
    }, [history])

    const viewLogs = useCallback((appId: string) => () => {
        history.push(`./accessManagement/${appId}/logs`)
    }, [history])

    if (store.applications.length === 0 && !store.isBusy) {
        return <NoApplications />;
    }

    if (store.isBusy) {
        return <BusyOverlay isBusy={store.isBusy} />
    }

    return <>
        { store.applications.map((stackGroup) => (
            <ApplicationListPaper key={stackGroup.stackId} elevation={6}>
                <Typography style={{ alignSelf: "center", fontWeight: 300, marginLeft: "10px" }} variant="h5">
                    {stackGroup.customerName}
                </Typography>
                <Table style={{ overflow: "hidden" }} >
                    <TableHead >
                        <TableRow >
                            <TableCell >Application</TableCell>
                            <TableCell align="left"></TableCell>
                            <LogsTableCell align="left"></LogsTableCell>
                            <TableCell >Location</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            stackGroup.apps.map((app) =>
                                (
                                    <TableRow key={app.name}>
                                        <TableCell scope="row" valign="top" >
                                            {app?.serviceInfo.appName ?? "-"}
                                        </TableCell>
                                        <TableCell align="center" >
                                            <Button startIcon={<SettingsIcon />} onClick={manageAppClick(app.id)} variant="contained" color="secondary">
                                                Manage
                                            </Button>
                                        </TableCell>
                                        <LogsTableCell align="center" >
                                            <LogsButton style={{ marginLeft: "10px" }} startIcon={<ListIcon />} onClick={viewLogs(app.id)} variant="contained" color="secondary">
                                                Logs
                                            </LogsButton>
                                        </LogsTableCell>
                                        <TableCell scope="row" valign="top" >
                                            <Link href={urlJoin(app?.serviceInfo.appRootUrl, app?.serviceInfo.appEntryPointUrl)}>Visit</Link>
                                        </TableCell>
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </ApplicationListPaper>
        ))
        }
    </>
});

const AccessManagment: FunctionComponent = observer(() => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <BreadcrumbNavigation style={{ margin: "15px 0px 15px 10px" }} />
            <ApplicationList />
        </div>
    );
})

export const AccessManagementMain: FunctionComponent = observer(() => {
    return (
        <AccessManagementStoreContextItem.ProviderComponent>
            <AccessManagment />
        </AccessManagementStoreContextItem.ProviderComponent>
    )
});