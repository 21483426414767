import { makeObservable, observable, computed, runInAction, action } from 'mobx'
import { createStoreContextItem } from '../../Common/StoreContextItem';
import { DisposableBase } from '../../Types';
import { AuthStore, AuthStoreContextItem, restClient, securityAdminRole } from "../../Common/Stores/AuthStore";
import { AuthAppItem, AuthUserItem, ChangeAccessForUserParameters, RequestParams } from '../../Clients/Api';
import gravatar from 'gravatar'
import { ItemRepository, ItemRepositoryContextItem } from '../../Common/Stores/ItemRepository';
import { computedFn } from 'mobx-utils';
import { UserProfileEditorStore } from './UserProfileEditorStore';

export class UserProfileStore extends DisposableBase {
    private _isBusy = false;
    private _abortController: AbortController = new AbortController();
    private _itemRepo: ItemRepository;
    private _profileEditorStore: UserProfileEditorStore;
    private _authStore: AuthStore;

    constructor(itemRepo: ItemRepository, authStore: AuthStore) {
        super()
        this._itemRepo = itemRepo;
        this._authStore = authStore;

        makeObservable<UserProfileStore, "_isBusy">(this, {
            _isBusy: observable,
            isBusy: computed,
            loadUserProfile: action,
            profile: observable,
            gravatarUrl: computed,
            errorMessageSet: action,
            errorMessage: observable,
            unregisterDialogOpen: observable,
            unverifiedApplicationId: observable,
            unverifiedApplication: computed,
            unverifiedApplicationIdSet: action
        })

        this._profileEditorStore = new UserProfileEditorStore(this);
        this._disposers.push(() => this._abortController.abort())
        this._disposers.push(() => this._profileEditorStore.dispose());
    }

    unverifiedApplicationId: string | null = null;

    unverifiedApplicationIdSet(value: string | null) {
        this.unverifiedApplicationId = value;
    }

    get unverifiedApplication() {
        if (!this.unverifiedApplicationId) {
            return undefined;
        }

        return this.getApplicationById(this.unverifiedApplicationId);
    }

    async verifyApplication(appId: string) {
        this._isBusy = true;

        try {
            await restClient.api.userManagementVerifyApplication({ appId }, this.requestParameters);
        } catch (e: any) {
            runInAction(() => {
                this.errorMessage = e.error.message as string;
            })

            throw e;
        } finally {
            this._isBusy = false;
        }
    }

    private get requestParameters(): RequestParams {
        return { signal: this._abortController.signal };
    }

    unregisterDialogOpen = false;

    unregisterDialogOpenSet(value: boolean) {
        this.unregisterDialogOpen = value;
    }

    unregisterApp: AuthAppItem | null = null

    unregisterAppSet(value: AuthAppItem) {
        this.unregisterApp = value;
    }

    async unregisterApplication() {
        if (!this.unregisterApp?.id || !this.profile?.email) {
            return;
        }
        const parameters: ChangeAccessForUserParameters = {
            email: this.profile.email,
            permittedRoles: undefined
        };

        this._isBusy = true;

        try {
            await restClient.api.userManagementChangeAccessForUser(this.unregisterApp.id, parameters, this.requestParameters);

            await this.reloadUserProfile();
        } catch (e: any) {
            runInAction(() => {
                this.errorMessage = e.error.message as string;
            })

            throw e;
        }
        finally {
            runInAction(() => {
                this._isBusy = false;
            })
        }
    }


    get authStore() {
        return this._authStore;
    }

    get profileEditor() {
        return this._profileEditorStore;
    }

    errorMessage: string | null = null;

    errorMessageSet(value: string = "") {
        this.errorMessage = value;
    }

    profile: AuthUserItem | null = null;

    setIsBusy(value: boolean) {
        this._isBusy = value;
    }

    get gravatarUrl() {
        const gravatarOptions: gravatar.Options = {
            d: "robohash",
            s: "200",
            protocol: 'https'
        }

        const gravatarUrl = gravatar.url(this.profile?.email.trim().toLocaleLowerCase() ?? "", gravatarOptions);

        return gravatarUrl;
    }

    isApplicationVerified = computedFn((id: string) => {
        if (this.authStore.isSuperUser) {
            return true;
        }

        if (!this.profile || this.profile.appAccess) {
            return false;
        }

        const profile = this.profile;

        for (let appAccess of profile.appAccess) {
            if (appAccess.appId === id) {
                return appAccess.verified;
            }
        }

        return false;
    })

    isApplicationManagable = computedFn((id: string) => {
        if (this.authStore.isSuperUser) {
            return true;
        }

        if (!this.profile || this.profile.appAccess) {
            return false;
        }

        const profile = this.profile;

        for (let appAccess of profile.appAccess) {
            if (appAccess.appId === id) {
                return appAccess.roleNamesAssigned.findIndex((role) => role === securityAdminRole) > -1;
            }
        }

        return false;
    })

    getApplicationById = computedFn((id: string) => {
        return this._itemRepo.applications.getApplicationById(id)
    });

    async reloadUserProfile(signal: AbortSignal = this._abortController.signal) {
        if (!this.profile) {
            return;
        }

        await this.loadUserProfile(this.profile?.userId, signal);
    }

    async loadUserProfile(userId: string, signal: AbortSignal = this._abortController.signal) {
        runInAction(() => {
            this._isBusy = true;
        })

        try {
            const profileResponse = await restClient.api.userManagementProfileGet(userId, { signal });
            runInAction(() => {
                this.profile = profileResponse.data;
            })
        } catch (e) {
            runInAction(() => {
                this.profile = null;
            })
        } finally {
            runInAction(() => {
                this._isBusy = false;
            });
        }
    }

    get isBusy() {
        return this._isBusy || this._itemRepo.applications.loading;
    }
}

export const UserProfileStoreContextItem = createStoreContextItem(() => {
    const authStore = AuthStoreContextItem.useStore();
    const itemRepo = ItemRepositoryContextItem.useStore();
    return () => new UserProfileStore(itemRepo, authStore);
});