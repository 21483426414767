import React from "react";
import { observer } from 'mobx-react-lite'
import IdentitiesLogoImage from '../../Resources/identities.png'

const Logo = observer((props: any) => {
    const style = props.style;
    return (
        <img alt="" style={{objectFit: "contain", ...style}} src={IdentitiesLogoImage} />
    )
});

export default Logo;
