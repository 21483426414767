import { makeObservable, observable, computed, runInAction, action } from 'mobx'
import { computedFn } from 'mobx-utils';
import { createStoreContextItem } from '../../Common/StoreContextItem';
import { DisposableBase } from '../../Types';
import { ItemRepository, ItemRepositoryContextItem } from '../../Common/Stores/ItemRepository';
import { AuthStore, AuthStoreContextItem, restClient } from "../../Common/Stores/AuthStore"
import { LoginRecordItem, RequestParams } from '../../Clients/Api';
import { saveAs } from 'file-saver';


export class ApplicationLogsStore extends DisposableBase {
    private _isBusy = false;
    private _abortController: AbortController = new AbortController();
    private _itemRepo: ItemRepository;
    private _appId: string | null = null;
    private _error: string | null = null;
    private _authStore: AuthStore;
    private _logRecords: LoginRecordItem[] = [];

    constructor(itemRepo: ItemRepository, authStore: AuthStore) {
        super()
        this._authStore = authStore;
        this._itemRepo = itemRepo;

        makeObservable<ApplicationLogsStore, "_logRecords" | "_isBusy" | "_appId" | "_error">(this, {
            _isBusy: observable,
            _appId: observable,
            _logRecords: observable,
            _error: observable,
            error: computed,
            isBusy: computed,
            application: computed,
            loadApplicationLoginRecords: action,
            resetError: action,
            logRecords: computed
        })

        this._disposers.push(() => this._abortController.abort())
    }

    private get requestParameters(): RequestParams {
        return { signal: this._abortController.signal };
    }

    get logRecords() {
        return this._logRecords;
    }

    async exportLoginRecords2(appId: string) {
        try {
            const response = await fetch(`/api/v1/app/${appId}/logs/logins/export`,
                {
                    body: JSON.stringify({ criteria: { applicationId: appId } }),
                    method: "POST",
                    headers: { 'Accept': 'application/zip', 'Content-Type': 'application/json' },
                    credentials: "same-origin",
                    redirect: "follow",
                    referrerPolicy: "no-referrer",
                    ...this.requestParameters
                });

            const disposition = response.headers.get('Content-Disposition');
            let filename = "login_log_export.zip";

            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            const blob = await response.blob();

            saveAs(blob, filename);
        } catch (e: any) {

        }
    }

    async exportLoginRecords(appId: string) {
        this._isBusy = true;

        try {
            const result = await restClient.api.appManagementSearchLoginRecords(appId, { orderBy: "", numberOfResults: 2000 }, this.requestParameters)

            const data = result.data as LoginRecordItem[];

            var csv = data.map(function(d){
                let {applicationName, ipAddress, instant, loginId} = d;
                ipAddress = ipAddress ?? " ";

                return JSON.stringify(Object.values({instant, applicationName, ipAddress, loginId}));
            })
            .join('\n') 
            .replace(/(^\[)|(\]$)/mg, '');
            
            const blob = new Blob([csv]);
            let filename = "login_log_export.csv";
            saveAs(blob, filename);
        } catch (e: any) {
            runInAction(() => {
                this._error = e.error.message as string;
            })
        } finally {
            runInAction(() => {
                this._isBusy = false;
            })
        }
    }

    async loadApplicationLoginRecords(appId: string) {
        this._appId = appId;

        this._isBusy = true;

        const startRow = this._logRecords.length;

        try {
            const result = await restClient.api.appManagementSearchLoginRecords(this._appId, { orderBy: "", numberOfResults: 35, startRow }, this.requestParameters)

            runInAction(() => {
                const oldRecords = this._logRecords;

                const newRecords = oldRecords.concat(result.data ?? []);

                this._logRecords = newRecords;
            });
        } catch (e: any) {
            runInAction(() => {
                this._error = e.error.message as string;
            })
        } finally {
            runInAction(() => {
                this._isBusy = false;
            })
        }
    }

    get error() {
        return this._error;
    }

    resetError() {
        this._error = null;
    }

    get appId() {
        return this._appId;
    }

    get application() {
        if (!this.appId) {
            return null;
        }

        return this.getApplicationById(this.appId);
    }

    getApplicationById = computedFn((id: string) => {
        return this._itemRepo.applications.getApplicationById(id)
    });

    get isBusy() {
        return this._isBusy || this._itemRepo.applications.loading;
    }
}

export const ApplicationLogsStoreContextItem = createStoreContextItem(() => {
    const authStore = AuthStoreContextItem.useStore();
    const itemRepo = ItemRepositoryContextItem.useStore();
    return () => new ApplicationLogsStore(itemRepo, authStore);
});