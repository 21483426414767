import { makeAutoObservable, runInAction } from 'mobx'
import { computedFn } from 'mobx-utils';
import { Api, PublicAuthInfo, SessionProfile } from '../../Clients/Api';
import { IDisposable } from '../../Types';
import { createStoreContextItem } from '../StoreContextItem';

export const restClient = new Api({ baseUrl: "." });

const superUserRole = "ecadmin";

export const securityAdminRole = "security admin";

export class AuthStore implements IDisposable {
    private _timer: NodeJS.Timeout;

    constructor() {
        makeAutoObservable(this)

        this._timer = setInterval(() => {
            if (!this.isLoggedIn) {
                return;
            }
            this.getProfile();
        }, 1000 * 60 * 5.1);
    }

    dispose() {
        clearInterval(this._timer)
    }

    loading = true;
    userProfile: SessionProfile | null = null;
    authInfo: PublicAuthInfo | null = null;

    get isLoggedIn() {
        return this.userProfile !== null;
    }

    get isSuperUser() {
        return this.hasRole([superUserRole]);
    }

    hasRole = computedFn((roles: string[]) : boolean => {
        const userRoles: string[] | undefined = this.userProfile?.roles;

        if (!userRoles) {
            return false;
        }

        const lowerCaseUserRoles = userRoles.map((role) => {
            return role.toLowerCase();
        });

        const hasRole = roles.some(role => lowerCaseUserRoles.includes(role.toLowerCase()));

        return hasRole;
    });

    async getAuthInfo() {
        try {
            this.loading = true;

            const authInfoResponse = await restClient.auth.userGetAuthInfo();

            runInAction(() => {
                this.authInfo = authInfoResponse.data;
            });
        } catch (e) {
            runInAction(() => {
                this.authInfo = null;
            });

        } finally {
            runInAction(() => {
                this.loading = false;
            });
        }
    }

    async getProfile() {
        try {
            runInAction(() => {
                this.loading = true;
            })

            const userProfileResponse = await restClient.auth.userGetProfile();

            runInAction(() => {
                this.userProfile = userProfileResponse.data as SessionProfile;
            })
        } catch (e) {
            runInAction(() => {
                this.userProfile = null;
            })
        } finally {
            runInAction(() => {
                this.loading = false;
            })
        }
    }
}

export const AuthStoreContextItem = createStoreContextItem(() => {
    return () => new AuthStore();
});