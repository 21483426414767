import React from "react";
import { observer } from "mobx-react-lite";
import { FunctionComponent } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { RoleRoute } from "../App";
import { UserProfileIndex } from "./UserProfile/UserProfileIndex";
import { AccessManagementIndex } from "./AccessManagement/AccessManagementIndex";
import { BreadcrumbNavigation } from "../Common/Navigation";

const SetupContent: FunctionComponent = observer(() => {
    return (
        <BreadcrumbNavigation style={{ margin: "15px 0px 15px 10px" }} />
    );
});

export const SetupMain: FunctionComponent = observer(() => {
    const match = useRouteMatch();
    return (
        <div id="content">
            <Switch>
                <RoleRoute path={`${match.path}`} exact>
                    <SetupContent />
                </RoleRoute>
                <RoleRoute path={`${match.path}/profile`}>
                    <UserProfileIndex />
                </RoleRoute>
                <RoleRoute path={`${match.path}/accessManagement`}>
                    <AccessManagementIndex />
                </RoleRoute>
                <Route>
                    Access Denied!
                </Route>
            </Switch>
        </div>
    );
});