
import React, { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useRouteMatch } from "react-router";
import { ApplicationLogsStoreContextItem } from "./ApplicationLogsStore";
import { Avatar, TableHead, TableBody, Table, TableCell, TableRow, Paper, Typography, Button, IconButton, Tooltip } from "@material-ui/core";
import gravatar from 'gravatar'
import { LoginRecordItem } from "../../Clients/Api";
import styled from "styled-components";
import { BreadcrumbNavigation } from "../../Common/Navigation";
import { RootContainer } from "./Components/ApplicationManagementComponents";
import { BusyOverlay } from "../../Common/Application/BusyOverlay";
import DownloadIcon from '@material-ui/icons/GetApp';

interface RouteMatch {
    appId: string;
}

export const AvatarStyled = styled(Avatar)`
    width: 50px;
    height: 50px;
    border-style: solid;
    border-width: 2px;
    border-color: rgba(127, 127, 127, 1);
`

export const DenseTableCell = styled(TableCell)`
    padding: 4px 4px 4px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70px;
`

const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric', 
    month: 'numeric', 
    day: 'numeric',
    hour12: true,
};

const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: true,
};

const LoginRecordRow: FunctionComponent<{ record: LoginRecordItem }> = observer((props) => {
    const { record } = props;

    const gravatarUrl = useMemo(() => {
        const gravatarOptions: gravatar.Options = {
            d: "robohash",
            s: "120",
            protocol: 'https'
        }
        return gravatar.url(record.loginId ?? "", gravatarOptions)
    }, [record]);

    const date = useMemo(() => {
        return new Date(Date.parse(record.instant))
    }, [record]);

    const dateString = useMemo(() => new Intl.DateTimeFormat('en-US', dateOptions).format(date), [date]);
    const timeString = useMemo(() => new Intl.DateTimeFormat('en-US', timeOptions).format(date), [date]);

    return (
        <TableRow>
            <DenseTableCell>
                <AvatarStyled src={gravatarUrl} />
            </DenseTableCell>
            <DenseTableCell>
                {record.loginId}
            </DenseTableCell>
            <DenseTableCell style={{ maxWidth: "70px", whiteSpace: "normal" }}>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    <Typography variant="body2">{dateString}&nbsp;</Typography>
                    <Typography variant="body2">{timeString}</Typography>
                </div>
            </DenseTableCell>
            <DenseTableCell>
                {record.ipAddress ?? "Registered"}
            </DenseTableCell>
        </TableRow>
    )
});

const ApplicationLogComponent = observer(() => {
    const match = useRouteMatch<RouteMatch>();
    const store = ApplicationLogsStoreContextItem.useStore();

    const appId = match.params.appId;

    const loadMore = useCallback(() => {
        store.loadApplicationLoginRecords(appId)
    }, [store, appId]);

    useEffect(() => {
        store.loadApplicationLoginRecords(appId)
    }, [store, appId]);

    const onDownloadLogs = useCallback(() => {
        store.exportLoginRecords(appId)
    }, [store, appId]);

    const application = store.getApplicationById(appId);

    const applicationTitle = application?.serviceInfo.appName ?? "";

    return (
        <RootContainer>
            <BusyOverlay isBusy={store.isBusy} />

            <BreadcrumbNavigation
                style={{ marginTop: "10px" }}
                currentTitle={`${applicationTitle} Login Records`}
            />
            <div style={{ alignSelf: "center", display: "flex", flexDirection: "row" }}>
                <Typography style={{ alignSelf: "center", fontWeight: 300, margin: "10px" }} variant="h5">
                    {
                        applicationTitle && `${applicationTitle} Login Records`
                    }
                </Typography>
                <Tooltip title="Download">
                    <IconButton onClick={onDownloadLogs}>
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
            </div>

            <div style={{ flex: "1 1 auto" }}>
                <Paper elevation={0}>
                    <Table  >
                        <TableHead >
                            <TableRow >
                                <TableCell style={{ width: "50px" }} ></TableCell>
                                <TableCell >User</TableCell>
                                <TableCell >Time</TableCell>
                                <TableCell >Source</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                store.logRecords.map((item, index) => (
                                    <LoginRecordRow key={index} record={item} />
                                ))
                            }
                        </TableBody>
                    </Table>
                </Paper>
                <Button onClick={loadMore}>
                    Load More
                </Button>
            </div>
        </RootContainer>
    )
})

export const ApplicationLogs = observer(() => {
    return (
        <ApplicationLogsStoreContextItem.ProviderComponent>
            <ApplicationLogComponent />
        </ApplicationLogsStoreContextItem.ProviderComponent>
    )
})