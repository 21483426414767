import React, { FunctionComponent, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { ApplicationManagementStoreContextItem } from '../ApplicationManagementStore';
import { TableCell, IconButton, Tooltip, Snackbar } from "@material-ui/core";
import styled from "styled-components";
import { AuthUserIdentityItem } from "../../../Clients/Api";
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloseIcon from '@material-ui/icons/Close';
import { FcOk, FcHighPriority } from "react-icons/fc";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export const RootContainer = styled.div`
    margin: 0px 5px 20px 5px;
    padding: 5px;
    display: flex;
    flex-direction: column;
`

export const DenseTableCell = styled(TableCell)`
    padding: 2px;
`

export const DenseInfoCell = styled(TableCell)`
    padding: 2px;
    @media (max-width: 568px) {
        display: none;
    }
`

export const CollapseButton = observer((props: { open: boolean }) => {
    const { open } = props;
    return (
        <IconButton size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
    )
})


export const VerifiedIcon: FunctionComponent<{ identity: AuthUserIdentityItem, style?: CSSProperties }> = observer((props) => {
    const { identity, style } = props;
    const store = ApplicationManagementStoreContextItem.useStore();
    const styleMods = { width: "20px", height: "20px" };
    const newStyle = { ...style, ...styleMods };

    const verified = identity.appAccess[0].verified;

    const onResendInviteClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (verified) {
            return;
        }

        event.stopPropagation();
        store.resendInvite(identity);
    }, [store, identity, verified])

    const tooltipTitle = verified ? "Verified" : "Resend invitation";

    return (
        <div style={{ display: "flex" }}>

            <Tooltip title={tooltipTitle}>
                <div>
                    <IconButton onClick={onResendInviteClick} disabled={verified}  >
                        {verified ? <FcOk style={newStyle} /> : <FcHighPriority style={newStyle} />}
                    </IconButton>
                </div>
            </Tooltip>
        </div>
    )
})

export const ApplicationManagementSnackbar = observer(() => {
    const store = ApplicationManagementStoreContextItem.useStore();
    const handleSnackbarClose = useCallback(() => {
        store.resetError();
    }, [store])

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={Boolean(store.error)}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={store.error}
            action={
                <React.Fragment>
                    <IconButton size="small" color="inherit" onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    )
})