import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useHistory, useRouteMatch } from 'react-router';
import { ApplicationManagementStoreContextItem } from './ApplicationManagementStore';
import { Table, TableCell, TableHead, Typography, TableRow, TableBody, Paper, Checkbox, Button, IconButton, TableContainer, Tooltip, Box, Collapse } from "@material-ui/core";
import { AuthAppItem, AuthAppRoleItem, AuthUserIdentityItem } from "../../Clients/Api";
import { Save as SaveIcon, Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { InviteUserDialog, UnregisterUserDialog } from "./Dialogs";
import { BreadcrumbNavigation } from "../../Common/Navigation";
import { BusyOverlay } from "../../Common/Application/BusyOverlay";
import { CollapseButton, DenseTableCell, VerifiedIcon, RootContainer, useRowStyles, ApplicationManagementSnackbar, DenseInfoCell } from "./Components/ApplicationManagementComponents";
import ListIcon from '@material-ui/icons/List';

interface RouteMatch {
    appId: string;
}

const ApplicationRolesRows = observer((props: { identity: AuthUserIdentityItem, application: AuthAppItem }) => {
    const { identity, application } = props;
    const store = ApplicationManagementStoreContextItem.useStore();
    const canModifyRoles = store.canModifyRoles;

    const onUserChangeRole = useCallback((userIdentity: AuthUserIdentityItem, role: AuthAppRoleItem) => () => {
        store.toggleRole(userIdentity, role);
    }, [store]);

    return (
        <>
            {
                application.rolesAvailable.map((role) => {
                    return (
                        <TableRow key={role.name}>
                            <DenseTableCell key={role.name} align="left">
                                <Checkbox
                                    disabled={!canModifyRoles}
                                    checked={store.roleIndex(identity, role) > -1}
                                    onChange={onUserChangeRole(identity, role)}
                                />
                            </DenseTableCell>
                            <DenseTableCell component="th" scope="row">
                                {role.name}
                            </DenseTableCell>
                            <DenseTableCell>
                                {role.description}
                            </DenseTableCell>
                        </TableRow>
                    )
                })}
        </>
    )
})

const UserInfoRow = observer((props: { identity: AuthUserIdentityItem }) => {
    const match = useRouteMatch<RouteMatch>();
    const store = ApplicationManagementStoreContextItem.useStore();
    const appId = match.params.appId;
    const application = store.getApplicationById(appId);
    const identity = props.identity;
    const rowClasses = useRowStyles();
    const [open, setOpen] = React.useState(false);

    const canModifyRoles = store.canModifyRoles;

    const saveUserClick = useCallback((userIdentity: AuthUserIdentityItem) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        store.saveUser(userIdentity);
    }, [store]);

    const onUnregisterUser = useCallback((userIdentity: AuthUserIdentityItem) => async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        store.unregisterDialogIdentitySet(userIdentity)
        store.unregisterDialogOpenSet(true);
    }, [store]);

    if (!application) {
        return null;
    }

    const roleCount = identity.appAccess[0]?.roleNamesAssigned?.length ?? 0;

    return (
        <>
            <TableRow key={identity.userId} className={rowClasses.root} onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}>
                <DenseTableCell scope="row">
                    <CollapseButton open={open} />
                </DenseTableCell>
                <DenseTableCell scope="row">
                    <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", alignItems: "center" }}>
                        <VerifiedIcon identity={identity} /><div>&nbsp;{identity.email}</div>
                    </div>
                </DenseTableCell>
                <DenseTableCell scope="row">
                    <Tooltip title="Remove">
                        <IconButton disabled={!canModifyRoles} onClick={onUnregisterUser(identity)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </DenseTableCell>
                <DenseInfoCell style={{ textAlign: "center", paddingRight: "2px" }}>
                    {roleCount} Role{`${roleCount > 1 || roleCount === 0 ? "s" : ""}`}
                </DenseInfoCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div" style={{ fontWeight: 300 }}>
                                Security
                            </Typography>
                            <Table size="small" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Role</TableCell>
                                        <TableCell>Description</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <ApplicationRolesRows identity={identity} application={application} />
                                </TableBody>
                                <caption>
                                    <Button
                                        disabled={!canModifyRoles}
                                        startIcon={<SaveIcon style={{ opacity: 0.6 }} />}
                                        onClick={saveUserClick(identity)}
                                    >
                                        Save
                                    </Button>
                                </caption>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
})

const AddUserComponent = observer(() => {
    const store = ApplicationManagementStoreContextItem.useStore();
    const application = store.application;
    const [dialogOpen, dialogOpenSet] = useState(false);
    const history = useHistory();

    const onAddUser = useCallback(() => {
        dialogOpenSet(true)
    }, [dialogOpenSet]);

    const onDialogClose = useCallback(() => {
        dialogOpenSet(false);
    }, [dialogOpenSet]);

    const onViewLogs = useCallback(() => {
        history.push(history.location.pathname + "/logs");
    },[history]);

    if (!application) {
        return null;
    }

    return (
        <div style={{ margin: "20px", alignSelf: "flex-end" }}>
            <Button startIcon={<AddIcon />} onClick={onAddUser}>
                Add User
            </Button>
            <Button style={{ marginLeft: "10px" }} startIcon={<ListIcon />} onClick={onViewLogs} >
                Logs
            </Button>
            <InviteUserDialog open={dialogOpen} onClose={onDialogClose} />
        </div>
    )
})



const ApplicationManagementComponent = observer(() => {
    const match = useRouteMatch<RouteMatch>();
    const store = ApplicationManagementStoreContextItem.useStore();
    const appId = match.params.appId;
    const application = store.getApplicationById(appId);

    useEffect(() => {
        store.loadApplication(appId);
    }, [store, appId]);

    if (!application) {
        return (
            <BusyOverlay isBusy={store.isBusy} />
        );
    }

    const applicationTitle = application.serviceInfo.appName;

    return (
        <RootContainer>
            <BreadcrumbNavigation
                style={{ marginTop: "10px" }}
                currentTitle={`${applicationTitle} User Management`}
            />
            <UnregisterUserDialog />
            <AddUserComponent />
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <DenseTableCell />
                            <DenseTableCell>User</DenseTableCell>
                            <DenseTableCell />
                            <DenseInfoCell>
                                Info
                            </DenseInfoCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            store.userIdentities.map((identity) => (
                                <UserInfoRow
                                    key={identity.userId}
                                    identity={identity}
                                />
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <ApplicationManagementSnackbar />
            <BusyOverlay isBusy={store.isBusy} />
        </RootContainer>
    )
})

export const ApplicationManagement = observer(() => (
    <ApplicationManagementStoreContextItem.ProviderComponent>
        <ApplicationManagementComponent />
    </ApplicationManagementStoreContextItem.ProviderComponent>
));