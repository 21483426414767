import { computed, makeObservable, observable, runInAction, action, observe } from "mobx";
import { AuthAppItem } from "../../Clients/Api";
import { restClient } from "./AuthStore";
import { ItemRepository } from "./ItemRepository";
import { DisposableBase } from "../../Types";
import _ from 'lodash';
import { computedFn } from "mobx-utils";

export class ApplicationRepository extends DisposableBase {
    private _rootStore: ItemRepository;
    private _loading: boolean = false;
    private _apps: AuthAppItem[] | null = null;
    private _abortController: AbortController = new AbortController();

    constructor(rootStore: ItemRepository) {
        super()

        makeObservable<ApplicationRepository, "_loading" | "_apps" | "loadAppList">(this, {
            _loading: observable,
            _apps: observable,
            loading: computed,
            apps: computed,
            groupedApps: computed,
            loadAppList: action
        });

        this._rootStore = rootStore;

        this._disposers.push(() => this._abortController.abort())

        this._disposers.push(observe(this._rootStore.auth, "isLoggedIn", data => {
            const loggedIn = data.newValue;

            if (loggedIn) {
                this.loadAppList(this._abortController.signal);
            }
        }));
    }

    get loading() {
        return this._loading;
    }

    get apps(): AuthAppItem[] {
        if (this._apps === null) {
            if (!this.loading) {
                this.loadAppList();
            }

            return [];
        }

        return this._apps;
    }

    getApplicationById = computedFn((id: string) => {
        return this._apps?.find((item)=>item.id === id);
    });

    get groupedApps() {
        const grouped = _
            .chain(this.apps)
            .groupBy((item) => item.serviceInfo.stackId)
            .map((value) => ({
                stackId: value[0].serviceInfo.stackId,
                stackName: value[0].serviceInfo.stackName,
                customerName: value[0].serviceInfo.customerName,
                apps: value
            }))
            .value();

        return grouped;
    }

    private async loadAppList(signal: AbortSignal = this._abortController.signal) {
        try {
            this._loading = true;

            const apps = await restClient.api.appManagementGetAllApps({ signal });
            
            runInAction(() => {
                this._apps = apps.data;
            });
        } catch (e) {
            runInAction(() => {
                if(this._apps) {
                    this._apps = null;
                }
            });
        } finally {
            runInAction(() => {
                this._loading = false;
            });
        }
    }
}
