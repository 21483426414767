
import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { RoleRoute } from "../../App";
import { AccessManagementMain } from "./AccessManagementMain";
import { ApplicationManagement } from "./ApplicationManagement";
import { ApplicationLogs } from "./ApplicationLogs";

export const AccessManagementIndex = observer(() => {
    const match = useRouteMatch();
    return (
        <Switch>
            <RoleRoute path={`${match.path}/:appId`} exact>
                <ApplicationManagement />
            </RoleRoute>
            <RoleRoute path={`${match.path}/:appId/logs`} exact>
                <ApplicationLogs />
            </RoleRoute>
            <RoleRoute path={`${match.path}/`} >
                <AccessManagementMain />
            </RoleRoute>

            <Route>
                Access Denied
            </Route>
        </Switch>
    )
})