import { makeObservable, observable, computed } from 'mobx'
import { createStoreContextItem } from '../../Common/StoreContextItem';
import { DisposableBase } from '../../Types';
import { ItemRepository, ItemRepositoryContextItem } from '../../Common/Stores/ItemRepository';
import { computedFn } from 'mobx-utils';

export class AccessManagementStore extends DisposableBase {
    private _isBusy = false;
    private _abortController: AbortController = new AbortController();
    private _itemRepo: ItemRepository;

    constructor(itemRepo: ItemRepository) {
        super()
        this._itemRepo = itemRepo;

        makeObservable<AccessManagementStore, "_isBusy">(this, {
            _isBusy: observable,
            isBusy: computed,
        })
    }

    get applications() {
        return this._itemRepo.applications.groupedApps;
    }

    getApplicationById = computedFn((id: string) => {
        return this._itemRepo.applications.getApplicationById(id)
    });

    get isBusy() {
        return this._isBusy || this._itemRepo.applications.loading;
    }
}

export const AccessManagementStoreContextItem = createStoreContextItem(() => {
    const itemRepo = ItemRepositoryContextItem.useStore();
    return () => new AccessManagementStore(itemRepo);
});