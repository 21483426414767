import React, { FunctionComponent } from "react";
import { Breadcrumbs, LinkProps, Link, withStyles, Chip, Theme } from '@material-ui/core';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home'
import { CSSProperties } from "@material-ui/core/styles/withStyles";
export const breadcrumbNameMap: { [key: string]: string } = {
    '/': 'Home',
    '/setup': "Setup",
    '/setup/profile': "Profile",
    '/setup/accessManagement': 'Access Management',
    '/setup/accessManagement/.': 'Application Management',
};

const StyledBreadcrumb = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.grey[200],
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        marginBottom: "6px",
        //fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[200],
        },
        '&:active': {
            //boxShadow: theme.shadows[1],
            //backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip) as typeof Chip; // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591


interface LinkRouterProps extends LinkProps {
    to: string;
    icon?: React.ReactElement;
    replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => {

    return (
        <StyledBreadcrumb icon={props.icon} label={
            <Link {...props} component={RouterLink as any} />
        } />

    )
};


export const BreadcrumbNavigation: FunctionComponent<{style?: CSSProperties, currentTitle?: string, className? : string }> = (props) => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
       
        <Breadcrumbs style={props.style} className={props.className}>
            <LinkRouter color="inherit" to="/" icon={<HomeIcon fontSize="small" />}>
                Home
            </LinkRouter>
            {pathnames.map((value, index) => {
              
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                return last || breadcrumbNameMap[to] === "Setup" ? (
                    <StyledBreadcrumb disabled={true} key={to} clickable={false} label={
                        breadcrumbNameMap[to] ??  props.currentTitle
                    }/>
                ) : breadcrumbNameMap[to] ? (
                        <LinkRouter color="inherit" to={to} key={to}>
                            {breadcrumbNameMap[to]}
                        </LinkRouter>
                    ) : (null)
            })}
        </Breadcrumbs>
    )
}