import React, { ReactNode } from 'react';
import ClearLogo from './eClearLogo';
import FacialRec from './FacialRec';
import IdentitiesDb from './IdentitiesDb';
import MediaTubes from './MediaTubes';
import NodeRedLogo from './NodeRedLogo';
import Statflow from './Statflow';
export interface ApplicationMetadata {
    icon: (style?: any | React.CSSProperties | undefined) => ReactNode;
}

export const defaultAppMetadata: ApplicationMetadata = {
    icon: (style: React.CSSProperties) => (
        <div style={{ ...style, padding: "18px", background: "#5d96ca", display: "flex" }}>
            <div style={{}}>
                <img alt="icon" src="./resources/defaultAppIcon.jpg" style={{ objectFit: "contain", width: "100%", height: "100%", }} />
            </div>
        </div>
    )
}

export const applicationMetadataLookup: { [key: string]: ApplicationMetadata } = {
    "nodered": {
        icon: (style: React.CSSProperties) => <NodeRedLogo style={style} />
    },
    "epass": {
        icon: (style: React.CSSProperties) => (
            <div style={{ ...style, background: "#0396a6", display: "flex", position: "relative" }}>
                <div style={{ margin: "0px", }}>
                    <img alt="icon" src="./resources/ePassIcon.png" style={{ objectFit: "contain", width: "100%", height: "100%", left: "60px", top: "30px", position: "absolute" }} />
                </div>
            </div>
        )
    },
    "eclear": {
        icon: (style: React.CSSProperties) => (
            <div style={{ ...style, padding: "100px", background: "url(./resources/hero3.jpg)" }}>
                <ClearLogo style={{ width: "100%", height: "100%", filter: "drop-shadow(0px 4px 8px black)" }} />
            </div>
        )
    },
    "identities": {
        icon: (style: React.CSSProperties) => (
            <IdentitiesDb style={style} />
        )
    },
    "statflow": {
        icon: (style: React.CSSProperties) => (
            <Statflow style={style} />
        )
    },
    "mediatubes-web": {
        icon: (style: React.CSSProperties) => (
            <MediaTubes style={style} />
        )
    },
    "facerec": {
        icon: (style: React.CSSProperties) => (
            <FacialRec style={style} />
        )
    },
}