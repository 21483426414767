import { Backdrop, CircularProgress } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React from "react";
import { FunctionComponent } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Theme } from '@material-ui/core/styles';

const useOverlayStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 500,
            color: '#fff',
        },
    }),
);

export const BusyOverlay: FunctionComponent<{isBusy: boolean}> = observer(({isBusy}) => {
    const classes = useOverlayStyles();

    return (
        <Backdrop className={classes.backdrop} open={isBusy}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
});