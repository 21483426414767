import { makeObservable, observable, runInAction, action, observe } from 'mobx';
import { DisposableBase } from '../../Types';
import { restClient } from "../../Common/Stores/AuthStore";
import { AuthUserItem, AuthUserProfileField, RequestParams } from '../../Clients/Api';
import { UserProfileStore } from './UserProfileStore';

const FieldMap = new Map<AuthUserProfileField, string>([
    [AuthUserProfileField.FullName, "fullName"],
    [AuthUserProfileField.FirstName, "firstName"],
    [AuthUserProfileField.LastName, "lastName"],
    [AuthUserProfileField.MobilePhone, "mobilePhone"],
    [AuthUserProfileField.Email, "email"],
    [AuthUserProfileField.TimeZone, "timezone"],
    [AuthUserProfileField.Username, "username"],
]);

export class UserProfileEditorStore extends DisposableBase {
    private _rootStore: UserProfileStore;
    private _abortController: AbortController = new AbortController();

    constructor(rootStore: UserProfileStore) {
        super();
        this._rootStore = rootStore;

        this._disposers.push(() => this._abortController.abort());
        this._disposers.push(observe(this._rootStore, "profile", change => {
            this.profile = Object.assign({}, this._rootStore.profile);
        }));

        this._disposers.push(() => {
            this._abortController.abort();
        });

        makeObservable(this, {
            profile: observable,
            save: action,
        });
    }

    profile: AuthUserItem | null = null;

    private get requestParams(): RequestParams {
        return { signal: this._abortController.signal };
    }

    async changePassword(currentPassword: string, newPassword: string) {
        const userId = this.profile?.userId;

        if (!userId) {
            return false;
        }

        this._rootStore.setIsBusy(false);

        try {
            await restClient.api.userManagementChangePassword(userId, { currentPassword: currentPassword, newPassword: newPassword }, this.requestParams);
            return true;
        } catch (e: any) {
            runInAction(() => {
                this._rootStore.errorMessageSet(e.error.message as string);
            });
        } finally {
            this._rootStore.setIsBusy(false);
        }

        return false;
    }

    async save(): Promise<boolean> {
        const userId = this.profile?.userId;

        if (!userId) {
            return true;
        }

        const promises: (() => Promise<void>)[] = [];

        const profileChange: any = this.profile;
        const currentProfile: any = this._rootStore.profile;

        FieldMap.forEach(async (fieldName: string, fieldKey: AuthUserProfileField) => {
            const currentFieldValue = profileChange[fieldName];

            if (currentFieldValue !== currentProfile[fieldName]) {
                promises.push(async () => {
                    await restClient.api.userManagementUpdateUserProfile(userId, fieldKey, currentFieldValue, this.requestParams);
                });
            }
        });

        if (promises.length === 0) {
            return true;
        }

        let success = true;

        this._rootStore.setIsBusy(true);
        for (let saveFieldPromise of promises) {
            try {
                await saveFieldPromise();
            } catch (e: any) {
                runInAction(() => {
                    this._rootStore.errorMessage = e.error.message as string;
                });
                success = false;
                break;
            } finally {
            }
        }

        try {
            await this._rootStore.reloadUserProfile();
        } catch (e) {
        } finally {
            this._rootStore.setIsBusy(false);
        }

        return success;
    }

}
