import { observable, action, computed } from 'mobx'
import { blueGrey, grey, blue, lime } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import Color from 'color';
import { createStoreContextItem } from '../StoreContextItem';

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#656565',
        },

    },
    overrides: {
        // Style sheet name ⚛️
        MuiButton: {
            // Name of the rule
            label: {
                color: "white"
            },

        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: grey[900]
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: grey[900],
            }
        },
        MuiMenuItem: {
            root: {
                padding: "10px",
                margin: "5px",
                borderRadius: "2px",
                borderStyle: "solid",
                borderWidth: "1px !important",
                borderColor: "transparent",
                '&:focus': {
                    backgroundColor: Color(lime[500]).alpha(0.1).toString() + " !important",
                },
                '&:hover': {
                    backgroundColor: Color(lime[500]).alpha(0.2).toString() + " !important",
                    borderStyle: "solid !important",
                    borderWidth: "1px !important",
                    borderColor: `${Color(lime[500]).alpha(0.4).toString()} !important`,
                },
                '&:active': {
                    backgroundColor: Color(lime[500]).alpha(0.1).toString() + " !important",
                    boxShadow: "0px 1px 2px 1px rgba(0, 0, 0,.35) inset !important",
                },
                '&$selected': {
                    borderStyle: "solid",
                    borderWidth: "1px !important",
                    borderColor: Color(grey[900]).toString() + " !important",
                    backgroundColor: `${Color(lime[500]).alpha(0.2).toString()} !important`,
                    boxShadow: "0px 1px 3px -1px rgba(0, 0, 0, .85) inset !important",
                }
            },
        }
    },
});

const lightTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: blue,
        secondary: blueGrey
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: grey[900]
            }
        },
        MuiPaper: {
            root: {
                backgroundColor: grey[50],
            }
        },
        MuiTable: {
            stickyHeader: {
                position: 'sticky',
                zIndex: 200,
                overflow: 'visible',
                fallbacks: {
                    position: '-webkit-sticky',
                },
            }

        },
        MuiMenuItem: {
            root: {
                padding: "10px",
                margin: "5px",
                borderRadius: "3px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "transparent",
                '&:focus': {
                    backgroundColor: Color(blueGrey[500]).alpha(0.1).toString() + " !important",
                },
                '&:hover': {
                    backgroundColor: Color(blueGrey[500]).alpha(0.2).toString() + " !important",
                    borderStyle: "solid !important",
                    borderWidth: "1px !important",
                    borderColor: `${Color(blueGrey[500]).alpha(0.4).toString()} !important`,
                },
                '&:active': {
                    backgroundColor: Color(blueGrey[500]).alpha(0.1).toString() + " !important",
                    boxShadow: "0px 1px 2px 1px rgba(0, 0, 0,.35) inset !important",
                },
                '&$selected': {
                    borderStyle: "solid",
                    borderWidth: "1px !important",
                    borderColor: `transparent !important`,
                    backgroundColor: `${Color(blueGrey[500]).alpha(0.1).toString()} !important`,
                    boxShadow: "0px 1px 3px -1px rgba(0, 0, 0, .85) inset !important",
                }
            },
        }
    },
})

lightTheme.typography.h2 = {
    fontSize: '1.4rem',
    '@media (min-width:600px)': {
        fontSize: '2.8rem',
    },
    [lightTheme.breakpoints.up('md')]: {
        fontSize: '3.75rem',
    },
};

darkTheme.typography.h2 = {
    fontSize: '1.4rem',
    '@media (min-width:600px)': {
        fontSize: '2.8rem',
    },
    [lightTheme.breakpoints.up('md')]: {
        fontSize: '3.75rem',
    },
};

const themeStorageKey = "_AppTheme"

class ThemeStore {
    constructor() {
        const themeName = localStorage.getItem(themeStorageKey)

        if (themeName) {
            //  this.theme = themeName === 'light' ? lightTheme : darkTheme;
        }
    }

    @computed get isLightTheme() {
        return this.theme.palette.type === 'light';
    }

    @action toggleTheme() {
        this.theme = this.isLightTheme ? darkTheme : lightTheme;

        localStorage.setItem(themeStorageKey, this.theme.palette.type);
    }

    @observable theme = lightTheme;
}

export const ThemeStoreContextItem = createStoreContextItem<ThemeStore>(() => {
    return () => new ThemeStore();
});
