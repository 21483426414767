import React, { FunctionComponent, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Chip, IconButton, Link } from "@material-ui/core";
import { AuthStoreContextItem } from "../../Common/Stores/AuthStore";
import { UserProfileStoreContextItem } from "./UserProfileStore";
import { BreadcrumbNavigation } from "../../Common/Navigation";
import { ProfileEditor } from "./ProfileEditor";
import { Delete as DeleteIcon } from '@material-ui/icons'
import { BusyOverlay } from "../../Common/Application/BusyOverlay";
import { UnregisterAppDialog, UnverifiedApplicationDialog } from "./Dialogs";
import { AuthAppItem, AuthUserAppAccessItem } from "../../Clients/Api";
import queryString from 'query-string';
import { EDIT_MODE_QUERY_PARAM, RoleTableCell, VerifiedIcon, UserProfileSnackbar, ApplicationListPaper, ProfileArea, ProfileBottomContainer, ProfileContent, ProfileInfoHorizontal, ProfileInfoVertical, ProfilePaper, ProfileRoot, ProfileTopContainer, UserProfileAvatar, NoApplications, UserProfileEditButton, DenseTableCell } from "./Components/UserProfileComponents";
import SettingsIcon from '@material-ui/icons/SettingsApplications'
import urlJoin from 'url-join';
import { useMemo } from "react";

interface ProfileUrlParams {
    userId?: string
}

export const UserProfile = observer(() => {
    const authStore = AuthStoreContextItem.useStore();
    const userProfileStore = UserProfileStoreContextItem.useStore();
    const params = useParams<ProfileUrlParams>();
    const location = useLocation();
    const userId = params.userId ?? authStore.userProfile?.userId;

    useEffect(() => {
        if (!userId) {
            return;
        }

        userProfileStore.loadUserProfile(userId);
    }, [userProfileStore, userId]);

    const profile = userProfileStore.profile;

    if (!profile) {
        return <BusyOverlay isBusy={userProfileStore.isBusy} />;
    }

    const isEditMode = Boolean(Object.keys(queryString.parse(location.search)).find((item) => item === EDIT_MODE_QUERY_PARAM));

    return (
        <ProfileRoot>
            <UnverifiedApplicationDialog />
            <BreadcrumbNavigation style={{ margin: "15px 0px 5px 10px" }} />
            <ProfilePaper>
                <UserProfileEditButton isEditMode={isEditMode} />
                <ProfileArea>
                    <UserProfileAvatar />

                    {
                        !isEditMode ? (
                            <ProfileContent>
                                <ProfileTopContainer>
                                    <ProfileInfoHorizontal
                                        label="Email"
                                        data={profile.email}
                                    />
                                    {/* <ProfileInfoHorizontal
                                        label="User Id"
                                        data={profile.userId}
                                    /> */}
                                    <div style={{ margin: "10px" }} />
                                </ProfileTopContainer>
                                <ProfileBottomContainer>
                                    <ProfileInfoVertical
                                        label="Username"
                                        data={profile.username}
                                    />
                                    <ProfileInfoVertical
                                        label="Mobile"
                                        data={profile.mobilePhone}
                                    />
                                    <ProfileInfoVertical
                                        label="Timezone"
                                        data={profile.timezone}
                                    />
                                </ProfileBottomContainer>
                            </ProfileContent>
                        ) : (
                                <ProfileEditor />
                            )
                    }
                </ProfileArea>
            </ProfilePaper>
            <ApplicationListPaper>
                <ApplicationList />
            </ApplicationListPaper>
            <UserProfileSnackbar />
            <BusyOverlay isBusy={userProfileStore.isBusy} />
        </ProfileRoot>
    )
})

const ApplicationListRow: FunctionComponent<{ appAccess: AuthUserAppAccessItem, application?: AuthAppItem }> = observer(({ appAccess, application }) => {
    const userProfileStore = UserProfileStoreContextItem.useStore();
    const profile = userProfileStore.profile;

    const onUnregisterApp = useCallback((app: AuthAppItem | undefined) => () => {
        if (!app) {
            return;
        }
        userProfileStore.unregisterAppSet(app);
        userProfileStore.unregisterDialogOpenSet(true)
    }, [userProfileStore]);

    const applicationUrl = useMemo(() => {
        const serviceInfo = application?.serviceInfo;
        var p1 = serviceInfo?.appRootUrl ?? '';
        var p2 = serviceInfo?.appEntryPointUrl ?? '';
        var final = urlJoin(p1, p2);;
        return final;
    }, [application])

    console.log({applicationUrl})

    if (!profile) {
        return null;
    }

    const appName = application?.name ?? appAccess.appId;
    const verified = userProfileStore.profile?.appAccess.find((item) => item.appId === appAccess.appId)?.verified ?? false
  
    return (
        <TableRow>
            <DenseTableCell scope="row" valign="top">
                {
                    userProfileStore.isApplicationManagable(appAccess.appId) && verified ? (
                        <Link style={{ display: "flex" }} component={RouterLink} to={`/setup/accessManagement/${appAccess.appId}`}>{appName} <SettingsIcon fontSize="small" /></Link>
                    ) : (
                            <>{appName}</>
                        )
                }
                 {/* {
                    verified && (
                        <Link href={applicationUrl}>
                            &nbsp;Visit
                        </Link>
                    )
                } */}
            </DenseTableCell>
          
            <DenseTableCell style={{width: "20px"}}  scope="row" valign="top">
                <VerifiedIcon appId={appAccess.appId} />
            </DenseTableCell>
            <DenseTableCell scope="row" valign="top">
                <Tooltip title="Remove">
                    <IconButton style={{width: "20px"}} onClick={onUnregisterApp(application)} >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </DenseTableCell>
            <RoleTableCell align="left">
                {
                    application?.rolesAvailable.map((item) =>
                        <Chip
                            key={item.name}
                            style={{ margin: "2px" }}
                            variant="outlined"
                            size="small"
                            label={item.name}
                        />
                    )
                }
            </RoleTableCell>
        </TableRow>
    )
})

const ApplicationList = observer(() => {
    const userProfileStore = UserProfileStoreContextItem.useStore();
    const profile = userProfileStore.profile;

    if (!profile) {
        return null;
    }

    const appAccess = profile.appAccess.filter((item) => {
        const application = userProfileStore.getApplicationById(item.appId);
        return Boolean(application)
    });

    if (appAccess.length === 0) {
        return <NoApplications />;
    }

    return (
        <>
            <UnregisterAppDialog />
            <Table >
                <TableHead>
                    <TableRow>
                        <TableCell>Application</TableCell>
                        <TableCell>
                            Verified
                        </TableCell>
                        <TableCell />
                        <TableCell align="left">Roles</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        appAccess.filter((item) => {
                            const application = userProfileStore.getApplicationById(item.appId);
                            return Boolean(application)
                        }).map((row) => {
                            const application = userProfileStore.getApplicationById(row.appId);

                            return (
                                <ApplicationListRow
                                    key={row.appId}
                                    appAccess={row}
                                    application={application}
                                />
                            )
                        })}
                </TableBody>
            </Table>
        </>
    )
});