import React, { FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react-lite'
import { AuthStoreContextItem } from './Common/Stores/AuthStore';
import { Paper, ThemeProvider, Typography, CssBaseline, CardMedia } from '@material-ui/core';
import { ThemeStoreContextItem } from './Common/Stores/ThemeStore';
import { BrowserRouter as Router, Route, RouteProps, Switch } from 'react-router-dom';
import styled from 'styled-components'
import MainHeader from './Common/Components/Header/MainHeader';
import Welcome from './Common/Splash/Welcome';
import { ItemRepositoryContextItem } from './Common/Stores/ItemRepository';
import { SetupMain } from './Setup/SetupMain';

const AppContentContainer = styled(Paper)`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`

export const Spacer = styled.div`
    width: 100%;
    height: 240px;
    object-fit: cover;
`

export const HeroContainer = styled.div`
    width: 100%;
    max-height: 240px;
`

const AppContents: FunctionComponent = observer(() => {

    return (
        <div id="content" style={{height: "100vh", display: "flex", flexDirection: "column", flex: 1}}>
            
            <HeroContainer>
                <Spacer />
            </HeroContainer>
            <Switch>
                <RoleRoute path="/" exact>
                    <div>
                    <Welcome />
                    </div>
                </RoleRoute>
                <RoleRoute path="/setup">
                    <SetupMain />
                </RoleRoute>
                <Route>
                    <div>No Route</div>
                </Route>
            </Switch>
        </div>
    );
})

const ImgMediaCard: FunctionComponent = () => {
    
    return (

      
          <CardMedia
            key="iFrameCardMedia"
            component="iframe"
            style={{flex: 1}}
            // image="https://eclear360.com"
            image="https://www.econnectglobal.com/solutions/eclear-rapid-access-control-suite"
            title="eConnect"
          />
          
      
    );
  }

export interface IRoleRoute extends RouteProps {
    roles?: string[];
}

export const RoleRoute: FunctionComponent<IRoleRoute> = observer((props) => {
    const authStore = AuthStoreContextItem.useStore();
    const isLoggedIn = authStore.isLoggedIn;
    const hasRole = !props.roles ? true : authStore.hasRole(props.roles)

    if(!isLoggedIn){
        return (<ImgMediaCard />);
    }

    if (isLoggedIn && hasRole) {
        return (
            <Route {...props} />
        )
    } else {
        return <Typography></Typography>
    }
})

const AppMain: FunctionComponent = observer(() => {
    const store = AuthStoreContextItem.useStore();
    useEffect(() => {
        store.getProfile()
        store.getAuthInfo();
    }, [store])

    return (
        <AppContentContainer>
            <MainHeader />
            <AppContents />
        </AppContentContainer>
    );
});

const AppRoot: FunctionComponent = observer(() => {
    const themeStore = ThemeStoreContextItem.useStore();

    var base = document.querySelector('base');

    let baseHref = base?.getAttribute("href");

    baseHref = baseHref || "/";

    return (
        <AuthStoreContextItem.ProviderComponent>
            <ItemRepositoryContextItem.ProviderComponent>
                <ThemeProvider theme={themeStore.theme}>
                    <CssBaseline />
                    <Router basename={baseHref}>
                        <AppMain />
                    </Router>
                </ThemeProvider >
            </ItemRepositoryContextItem.ProviderComponent>
        </AuthStoreContextItem.ProviderComponent>
    )
})

const App: FunctionComponent = observer(() => {
    return (
        <ThemeStoreContextItem.ProviderComponent>
            <AppRoot />
        </ThemeStoreContextItem.ProviderComponent>
    )
})

export default App;
