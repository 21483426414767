/* tslint:disable */
/* eslint-disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AuthAppDataItem {
  appName: string;
  appRootUrl: string;
  appEntryPointUrl: string;
  appType: string;
  stackId: string;
  stackName: string;
  customerName: string;
}

export interface AuthAppRoleItem {
  name: string;
  description: string;
  defaultRole: boolean;
  superRole: boolean;
}

export interface AuthAppItem {
  id: string;
  name: string;
  serviceInfo: AuthAppDataItem;
  rolesAvailable: AuthAppRoleItem[];
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

export interface LoginRecordItem {
  applicationId: string;
  instant: string;
  ipAddress: string;
  userId: string;
  applicationName: string;
  loginId: string;
}

export interface LoginRecordExportCriteria {
  applicationId?: string | null;
  end?: string | null;
  start?: string | null;
  userId?: string | null;
}

export interface LoginRecordExportParameters {
  criteria?: LoginRecordExportCriteria;
  dateTimeSecondsFormat?: string | null;
  zoneId?: string | null;
}

export interface AuthAppRoleSyncItem {
  clientId: string;
  clientSecret: string;
  availableRoles: AuthAppRoleItem[];
  entryPointUri: string;
}

export interface AuthAppSyncItem {
  clientId: string;
  clientSecret: string;
  availableRoles: AuthAppRoleItem[];
  entryPointUri: string;
}

export interface JwtAppSettingsItem {
  enableJwtAuth: boolean;
}

export interface SessionProfile {
  userId: string;
  username: string;
  givenName: string;
  email: string;
  roles: string[];
  token: string;
  expires: string;
}

export interface PublicAuthInfo {
  clientId: string;
  authHost: string;
  callback: string;
  portalUri: string;
}

export interface ApiError {
  message?: string | null;
}

export interface AuthUserAppAccessItem {
  appId: string;
  userId: string;
  verified: boolean;
  roleNamesAssigned: string[];
}

export interface AuthUserIdentityItem {
  userId: string;
  email: string;
  firstName: string;
  appAccess: AuthUserAppAccessItem[];
}

export interface AuthUserItem {
  userId: string;
  email: string;
  firstName: string;
  appAccess: AuthUserAppAccessItem[];
  lastName: string;
  username: string;
  active: boolean;
  mobilePhone: string;
  timezone: string;
}

export interface ChangeAccessForUserParameters {
  email?: string | null;
  permittedRoles?: string[] | null | null;
}

export interface ChangePasswordParameters {
  currentPassword?: string | null;
  newPassword?: string | null;
}

export enum AuthUserProfileField {
  FirstName = "FirstName",
  LastName = "LastName",
  FullName = "FullName",
  Username = "Username",
  TimeZone = "TimeZone",
  Email = "Email",
  MobilePhone = "MobilePhone",
}

export type RequestParams = Omit<RequestInit, "body" | "method"> & {
  secure?: boolean;
};

export type RequestQueryParamsType = Record<string | number, any>;

interface ApiConfig<SecurityDataType> {
  baseUrl?: string;
  baseApiParams?: RequestParams;
  securityWorker?: (securityData: SecurityDataType) => RequestParams;
}

/** Overrided Promise type. Needs for additional typings of `.catch` callback */
type TPromise<ResolveType, RejectType = any> = Omit<Promise<ResolveType>, "then" | "catch"> & {
  then<TResult1 = ResolveType, TResult2 = never>(
    onfulfilled?: ((value: ResolveType) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: RejectType) => TResult2 | PromiseLike<TResult2>) | undefined | null,
  ): TPromise<TResult1 | TResult2, RejectType>;
  catch<TResult = never>(
    onrejected?: ((reason: RejectType) => TResult | PromiseLike<TResult>) | undefined | null,
  ): TPromise<ResolveType | TResult, RejectType>;
};

interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D | null;
  error: E | null;
}

enum BodyType {
  Json,
}

class HttpClient<SecurityDataType> {
  public baseUrl: string = "";
  private securityData: SecurityDataType = null as any;
  private securityWorker: null | ApiConfig<SecurityDataType>["securityWorker"] = null;

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType) => {
    this.securityData = data;
  };

  private addQueryParam(query: RequestQueryParamsType, key: string) {
    return (
      encodeURIComponent(key) + "=" + encodeURIComponent(Array.isArray(query[key]) ? query[key].join(",") : query[key])
    );
  }

  protected addQueryParams(rawQuery?: RequestQueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys.length
      ? `?${keys
          .map((key) =>
            typeof query[key] === "object" && !Array.isArray(query[key])
              ? this.addQueryParams(query[key] as object).substring(1)
              : this.addQueryParam(query, key),
          )
          .join("&")}`
      : "";
  }

  private bodyFormatters: Record<BodyType, (input: any) => any> = {
    [BodyType.Json]: JSON.stringify,
  };

  private mergeRequestOptions(params: RequestParams, securityParams?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params,
      ...(securityParams || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params.headers || {}),
        ...((securityParams && securityParams.headers) || {}),
      },
    };
  }

  private safeParseResponse = <T = any, E = any>(response: Response): Promise<HttpResponse<T, E>> => {
    const r = response as HttpResponse<T, E>;
    r.data = null;
    r.error = null;

    return response
      .json()
      .then((data) => {
        if (r.ok) {
          r.data = data;
        } else {
          r.error = data;
        }
        return r;
      })
      .catch((e) => {
        r.error = e;
        return r;
      });
  };

  public request = <T = any, E = any>(
    path: string,
    method: string,
    { secure, ...params }: RequestParams = {},
    body?: any,
    bodyType?: BodyType,
    secureByDefault?: boolean,
  ): TPromise<HttpResponse<T, E>> => {
    const requestUrl = `${this.baseUrl}${path}`;
    const secureOptions =
      (secureByDefault || secure) && this.securityWorker ? this.securityWorker(this.securityData) : {};
    const requestOptions = {
      ...this.mergeRequestOptions(params, secureOptions),
      method,
      body: body ? this.bodyFormatters[bodyType || BodyType.Json](body) : null,
    };

    return fetch(requestUrl, requestOptions).then(async (response) => {
      const data = await this.safeParseResponse<T, E>(response);
      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title eConnect Portal
 * @version v1
 */
export class Api<SecurityDataType = any> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @tags AppManagement
     * @name AppManagement_GetAppById
     * @request GET:/api/v1/app/{id}
     * @response `200` `AuthAppItem` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    appManagementGetAppById: (id: string | null, params?: RequestParams) =>
      this.request<AuthAppItem, ProblemDetails>(`/api/v1/app/${id}`, "GET", params),

    /**
     * @tags AppManagement
     * @name AppManagement_GetAllApps
     * @request GET:/api/v1/app
     * @response `200` `(AuthAppItem)[]` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    appManagementGetAllApps: (params?: RequestParams) =>
      this.request<AuthAppItem[], ProblemDetails>(`/api/v1/app`, "GET", params),

    /**
     * @tags AppManagement
     * @name AppManagement_SearchLoginRecords
     * @request GET:/api/v1/app/{applicationId}/logs/logins
     * @response `200` `(LoginRecordItem)[]` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    appManagementSearchLoginRecords: (
      applicationId: string | null,
      query?: {
        end?: string | null;
        start?: string | null;
        userId?: string | null;
        numberOfResults?: number | null;
        orderBy?: string | null;
        startRow?: number | null;
        retrieveTotal?: boolean;
      },
      params?: RequestParams,
    ) =>
      this.request<LoginRecordItem[], ProblemDetails>(
        `/api/v1/app/${applicationId}/logs/logins${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags AppManagement
     * @name AppManagement_ExportLoginRecords
     * @request POST:/api/v1/app/{applicationId}/logs/logins/export
     * @response `200` `File` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    appManagementExportLoginRecords: (
      applicationId: string | null,
      data: LoginRecordExportParameters,
      params?: RequestParams,
    ) => this.request<File, ProblemDetails>(`/api/v1/app/${applicationId}/logs/logins/export`, "POST", params, data),

    /**
     * @tags AppManagement
     * @name AppManagement_UpdateRoles
     * @request POST:/api/v1/app/rolesSync
     * @response `200` `any` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    appManagementUpdateRoles: (data: AuthAppRoleSyncItem, params?: RequestParams) =>
      this.request<any, ProblemDetails>(`/api/v1/app/rolesSync`, "POST", params, data),

    /**
     * @tags AppManagement
     * @name AppManagement_ApplicationSync
     * @request POST:/api/v1/app/applicationSync
     * @response `200` `any` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    appManagementApplicationSync: (data: AuthAppSyncItem, params?: RequestParams) =>
      this.request<any, ProblemDetails>(`/api/v1/app/applicationSync`, "POST", params, data),

    /**
     * @tags UserManagement
     * @name UserManagement_VerifyApplication
     * @request GET:/api/v1/users/verifyApplication
     * @response `200` `any` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `500` `ApiError` Server Error
     */
    userManagementVerifyApplication: (query?: { appId?: string }, params?: RequestParams) =>
      this.request<any, ProblemDetails | ApiError>(
        `/api/v1/users/verifyApplication${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags UserManagement
     * @name UserManagement_GetAllFromAppIds
     * @request GET:/api/v1/users/getAll
     * @response `200` `(AuthUserIdentityItem)[]` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `500` `ApiError` Server Error
     */
    userManagementGetAllFromAppIds: (query?: { appId?: string[] | null | null }, params?: RequestParams) =>
      this.request<AuthUserIdentityItem[], ProblemDetails | ApiError>(
        `/api/v1/users/getAll${this.addQueryParams(query)}`,
        "GET",
        params,
      ),

    /**
     * @tags UserManagement
     * @name UserManagement_ProfileGet
     * @request GET:/api/v1/users/{userId}
     * @response `200` `AuthUserItem` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `403` `ProblemDetails` Forbidden
     * @response `404` `ProblemDetails` Not Found
     * @response `500` `ApiError` Server Error
     */
    userManagementProfileGet: (userId: string, params?: RequestParams) =>
      this.request<AuthUserItem, ProblemDetails | ApiError>(`/api/v1/users/${userId}`, "GET", params),

    /**
     * @tags UserManagement
     * @name UserManagement_ChangeAccessForUser
     * @request POST:/api/v1/users/access/{appId}
     * @response `200` `boolean` Success
     * @response `400` `ApiError` Bad Request
     * @response `401` `ApiError` Unauthorized
     * @response `403` `ApiError` Forbidden
     * @response `404` `ApiError` Not Found
     * @response `500` `ApiError` Server Error
     */
    userManagementChangeAccessForUser: (appId: string, data: ChangeAccessForUserParameters, params?: RequestParams) =>
      this.request<boolean, ApiError>(`/api/v1/users/access/${appId}`, "POST", params, data),

    /**
     * @tags UserManagement
     * @name UserManagement_ResendAppRegistrationEmail
     * @request POST:/api/v1/users/resendAppRegistrationEmail/{appId}
     * @response `200` `ApiError` Success
     * @response `404` `ApiError` Not Found
     * @response `500` `ApiError` Server Error
     */
    userManagementResendAppRegistrationEmail: (
      appId: string,
      query?: { email?: string | null },
      params?: RequestParams,
    ) =>
      this.request<ApiError, ApiError>(
        `/api/v1/users/resendAppRegistrationEmail/${appId}${this.addQueryParams(query)}`,
        "POST",
        params,
      ),

    /**
     * @tags UserManagement
     * @name UserManagement_ChangePassword
     * @request POST:/api/v1/users/{userId}/changePassword
     * @response `200` `ApiError` Success
     * @response `400` `ApiError` Bad Request
     * @response `403` `ApiError` Forbidden
     * @response `404` `ApiError` Not Found
     */
    userManagementChangePassword: (userId: string, data: ChangePasswordParameters, params?: RequestParams) =>
      this.request<ApiError, ApiError>(`/api/v1/users/${userId}/changePassword`, "POST", params, data),

    /**
     * @tags UserManagement
     * @name UserManagement_UpdateUserProfile
     * @request POST:/api/v1/users/{userId}/{profileFieldType}
     * @response `200` `ApiError` Success
     * @response `400` `ApiError` Bad Request
     * @response `403` `ApiError` Forbidden
     * @response `404` `ApiError` Not Found
     */
    userManagementUpdateUserProfile: (
      userId: string,
      profileFieldType: AuthUserProfileField,
      data: string | null,
      params?: RequestParams,
    ) => this.request<ApiError, ApiError>(`/api/v1/users/${userId}/${profileFieldType}`, "POST", params, data),
  };
  auth = {
    /**
     * @tags AuthSettings
     * @name AuthSettings_GetJwtSettings
     * @request GET:/auth/v1/settings/jwt
     * @response `200` `JwtAppSettingsItem` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    authSettingsGetJwtSettings: (params?: RequestParams) =>
      this.request<JwtAppSettingsItem, ProblemDetails>(`/auth/v1/settings/jwt`, "GET", params),

    /**
     * @tags AuthSettings
     * @name AuthSettings_SetJwtSettings
     * @request POST:/auth/v1/settings/jwt
     * @response `200` `any` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    authSettingsSetJwtSettings: (data: JwtAppSettingsItem, params?: RequestParams) =>
      this.request<any, ProblemDetails>(`/auth/v1/settings/jwt`, "POST", params, data),

    /**
     * @tags User
     * @name User_Login
     * @request GET:/auth/v1/user/login
     * @response `200` `any` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    userLogin: (query?: { next?: string | null }, params?: RequestParams) =>
      this.request<any, ProblemDetails>(`/auth/v1/user/login${this.addQueryParams(query)}`, "GET", params),

    /**
     * @tags User
     * @name User_Logout
     * @request GET:/auth/v1/user/logout
     * @response `200` `any` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    userLogout: (params?: RequestParams) => this.request<any, ProblemDetails>(`/auth/v1/user/logout`, "GET", params),

    /**
     * @tags User
     * @name User_GetProfile
     * @request GET:/auth/v1/user/profile
     * @response `200` `SessionProfile` Success
     * @response `400` `ProblemDetails` Bad Request
     */
    userGetProfile: (params?: RequestParams) =>
      this.request<SessionProfile, ProblemDetails>(`/auth/v1/user/profile`, "GET", params),

    /**
     * @tags User
     * @name User_GetAuthInfo
     * @request GET:/auth/v1/user/authinfo
     * @response `200` `PublicAuthInfo` Success
     * @response `400` `ProblemDetails` Bad Request
     * @response `500` `any` Server Error
     */
    userGetAuthInfo: (params?: RequestParams) =>
      this.request<PublicAuthInfo, ProblemDetails>(`/auth/v1/user/authinfo`, "GET", params),
  };
}
