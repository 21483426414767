import React from 'react';

const Logo = (props) => {
    const { style } = props;
    
    return (
            <svg style={style} height={style.height} width={style.width} version="1.1" viewBox="0 0 87.919 100.57">
                <g transform="translate(-60.362 -83.564)">
                    <path d="m144.06 83.564h-79.486c-2.3326 0-4.2164 1.8841-4.2164 4.2164v92.135c0 2.3326 1.8841 4.2164 4.2164 4.2164h79.486c2.3326 0 4.2164-1.8841 4.2164-4.2164v-92.225c0-2.2429-1.8838-4.1267-4.2164-4.1267z" fill="#393d47" strokeWidth=".26458" />
                    <rect x="67.001" y="90.652" width="74.552" height="86.304" fill="#dededf" strokeWidth=".26458" />
                    <g transform="matrix(.26458 0 0 .26458 36.947 66.429)">
                        <rect x="113.59" y="135.63" width="281.77" height="79.004" fill="#6eb1e1" />
                        <rect x="138.68" y="113.93" width="42.723" height="10.172" fill="#6eb1e1" />
                        <g fill="#bdbdbe">
                            <rect x="201.75" y="113.93" width="42.723" height="10.172" />
                            <rect x="264.82" y="113.93" width="42.723" height="10.172" />
                            <rect x="327.88" y="113.93" width="42.723" height="10.172" />
                        </g>
                    </g>
                    <circle cx="104.32" cy="123.67" r="13.278" fill="#4b5aa7" strokeWidth=".26458" />
                    <g transform="matrix(.26458 0 0 .26458 36.947 66.429)">
                        <circle cx="254.64" cy="194.29" r="19.327" fill="#f1f3f7" />
                        <path d="m254.64 266.51c15.936 0 29.838-7.46 38.993-18.988-1.356-20.344-18.31-36.62-38.993-36.62s-37.637 15.936-38.993 36.62c9.154 11.529 23.056 18.988 38.993 18.988z" fill="#f1f3f7" />
                        <g fill="#bdbdbe">
                            <rect x="204.46" y="294.99" width="100.37" height="13.563" />
                            <rect x="138.68" y="374" width="231.59" height="13.563" />
                            <rect x="235.32" y="328.9" width="38.654" height="13.563" />
                        </g>
                    </g>
                    <circle cx="104.32" cy="180.45" r="2.3326" fill="#4f5565" strokeWidth=".26458" />
                </g>
            </svg>
    )
}

export default Logo;